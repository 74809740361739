import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/* 
HEADER WRAPPER
 */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledHeaderWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d1d1d1;
  background-color: #ffffff;

  padding: 0.1rem 0.5rem;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
`;



export const HeaderContainer = React.memo(function HeaderContainer(props) {
  return (
    <StyledHeaderWrapper {...props} className={props.className}>
    {props.children}
  </StyledHeaderWrapper>
  );
});


HeaderContainer.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/*RIGHT PANE CONTAINER */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledContainerWrapper = styled.section`
  height: 100%;
  padding-bottom: 70px;
  /* padding-left: 273px; */ /* TODO */
  transition: all 0.2s ease-in-out;
  background: #ffffff;

  @media screen and (max-width: 768px) {
    padding-left: 0 !important;
  }

  /* ${props => props.resize || "padding-left: 52px"}; */ /* TODO */
  padding-left: 52px; /* TODO */
`;

export const RightPaneContainer = React.memo(function RightPaneContainer(props) {
  return (
    <StyledContainerWrapper {...props} className={props.className}>
      {props.children}
    </StyledContainerWrapper>
  );
});

RightPaneContainer.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/* 
RIGHT PANE CONTENT WRAPPER
 */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledRightPaneWrapper = styled.section`
  display: block;
  font-size: 1rem;
  height: calc(100vh - 5em);
  overflow: auto;
  scrollbar-color: #cccccc #e9ecef;
  /* scrollbar-width: thin; */
`;

export const RightPaneContent = React.memo(function RightPaneContent(props) {
  return (
    <StyledRightPaneWrapper {...props} className={props.className}>
    {props.children}
  </StyledRightPaneWrapper>
  );
});

RightPaneContent.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/* 
BODY CONTAINER WRAPPER 
*/
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledBodyContainerWrapper = styled.section`
  height: 100%;
  margin-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;


export const BodyContainer = React.memo(function BodyContainer(props) {
  return (
    <StyledBodyContainerWrapper {...props} className={props.className}>
    {props.children}
  </StyledBodyContainerWrapper>
  );
});

BodyContainer.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/* 
CUSTOM WRAPPER 
*/
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const Wrapper = styled.div`
  margin: 0;
  transition: all 1s ease-in-out;

  display: ${props => {
    switch (props.showWrapper) {
      case false:
        return "none";
      case true:
        return "block";
      default:
        return "block";
    }
  }};

  background: ${props => {
    switch (props.background) {
      case "basic":
        return "#FFFFFF";
      case "primary":
        return "#F9FaFB";
      case "secondary":
        /*  return "#EAEAEA"; */
        return "#EEEEEE";
      default:
        return "transparent";
    }
  }};

  padding: ${props => {
    switch (props.padding) {
      case "small":
        return "4px";
      case "big":
        return "10px";
      case "large":
        return "20px";
      default:
        return "0";
    }
  }};

  border: ${props => {
    switch (props.border) {
      case "solid":
        return "1px solid #cccccc";
      case "dashed":
        return "1px dashed #cccccc";
      default:
        return "0px dashed #cccccc";
    }
  }};

  ${props => !props.marginAll || "margin: 1em;"};
  ${props => !props.marginTop || "margin-top: 1em;"};
  ${props => !props.marginRight || "margin-right: 1em;"};
  ${props => !props.marginLeft || "margin-left: 1em;"};
  ${props => !props.marginBottom || "margin-bottom: 1em;"};
  ${props => !props.borderRadius || "border-radius: 5px;"};


  ${props => !props.showBlurer || "position: relative;"};
  ${props => !props.showBlurer || "filter: blur(1px); grayscale(.5);"}; 

  > .blurerOverlay{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width:100%;
      background: rgba(255,255,255, .7);
      z-index:1;
  }
`;


export const CustomWrapper = React.memo(function CustomWrapper(props) {
  return (
    <Wrapper {...props} className={props.className}>
      {(props.showBlurer ? <div className="blurerOverlay" /> : null)}
      {props.children}
    </Wrapper>
  );
});

CustomWrapper.propTypes = {
  className: PropTypes.string,
  showWrapper: PropTypes.bool,
  background: PropTypes.string,
  padding: PropTypes.string,
  border: PropTypes.string,
  marginAll: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginLeft: PropTypes.bool,
  marginBottom: PropTypes.bool,
  borderRadius: PropTypes.bool,
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<



/* 
FLEX BOX CONTAINER WRAPPER
 */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledFlex = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  /* height: 100%; */
  background: transparent;
`;

export const FlexContainer = React.memo(function FlexContainer(props) {
  return (
    <StyledFlex {...props} className={props.className}>
      {props.children}
    </StyledFlex>
  );
});

HeaderContainer.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<



/* 
AMOUNT CONTAINER WRAPPER
 */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledAmonut = styled.div`
  width: 100%;
  padding: 10px;
  background: ${props => (props.basic)? 'transparent' : "#FFFFFF"};
  border-radius: 5px;
  color: #3e4856;
  font-weight: 600;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: space-between;
`;

export const AmountWrapper = React.memo(function AmountWrapper(props) {
  return (
    <StyledAmonut {...props} className={props.className}>
      {props.children}
    </StyledAmonut>
  );
});

AmountWrapper.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


/* 
FOOTER TOOTAL AMOUNT WRAPPER
 */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledFooterTotalAmonut = styled.div`
  color: #3e4856;
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;

  > :last-child {
      color: #ffffff;
  }
`;

export const FooterTotalAmountWrapper = React.memo(function FooterTotalAmountWrapper(props) {
  return (
    <StyledFooterTotalAmonut {...props} className={props.className}>
      {props.children}
    </StyledFooterTotalAmonut>
  );
});

FooterTotalAmountWrapper.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


/* 
HEADER TOOTAL AMOUNT WRAPPER
 */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledHeaderTotalAmonut = styled.div`
  text-align: right;
  color: #3e4856;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  padding-right: 10px;
`;

export const HeaderTotalAmountWrapper = React.memo(function HeaderTotalAmountWrapper(props) {
  return (
    <StyledHeaderTotalAmonut {...props} className={props.className}>
      {props.children}
    </StyledHeaderTotalAmonut>
  );
});

HeaderTotalAmountWrapper.propTypes = {
  className: PropTypes.string
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<