

const platform = {
    online: 'https://www.rainbowinks.pm1.biz',
    local: 'http://localhost/sites/smartabk',
};


export const URL = (process.env.NODE_ENV === 'development') ? '' : platform.online;
export const API_PATH = (process.env.NODE_ENV === 'development') ? 'http://localhost/sites/smartabk/api/v1/' : `${URL}/api/v1/`;




