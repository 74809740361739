import { GET_UNITS_OF_MEASUREMENT } from '../actions/actionConstants';

const initialState = {
    unitsOfMeasurement: [],
};

const unitsOfMeasurementReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_UNITS_OF_MEASUREMENT:
            return {
                ...state,
                unitsOfMeasurement: action.payload,
            };
        default:
            return state;
    }
};

export default unitsOfMeasurementReducer;
