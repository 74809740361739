import {
    GET_CAPITAL_ACCOUNTS
} from '../actions/actionConstants';

const initialState = {
    capitalAccounts: [],
    capitalAccountsCount: 0,
    
    capitalTransactions: [],
    capitalTransactionsCount: 0,
};

const capitalReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_CAPITAL_ACCOUNTS:
            return {
                ...state,
                capitalAccounts: action.payload.records,
                capitalAccountsCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default capitalReducer;
