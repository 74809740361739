import React from 'react';
import { Header, Image, Button } from 'semantic-ui-react';
import Logo from '../../../../images/logo_sm.png';
import './headerLeft.scss';

function HeaderLeft(props) {
	return (
		<Header as="h4" className="header-left-wrapper">
			<Button icon="bars" className="mobile-menu" onClick={props.handleMobileMenu} />
			<Image circular src={Logo} size="medium" />{' '}
			<span>
				Smart <em>ABK</em>
			</span>
		</Header>
	);
}

export default HeaderLeft;
