
import { GET_LEDGER_ACCOUNTS } from '../actions/actionConstants';

const initialState = {
    ledgerAccounts: [],
};

const accountsReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_LEDGER_ACCOUNTS:
            return {
                ...state,
                ledgerAccounts: action.payload,
            };
        default:
            return state;
    }
};

export default accountsReducer;