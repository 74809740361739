import React from 'react';
import { Dropdown, Image, Header, List, Icon } from 'semantic-ui-react';
import Img_upload_placeholder from '../../../../../images/user_avatar.png';
import './UserProfileDropdown.scss';
import { API_PATH } from "../../../../../utils/url.js";

const trigger = (fullName, profileImage) => (
	<div className="fullname-avatar">
		<div className="fullname d-md-none-force">{fullName}</div>
		<Image avatar src={profileImage} />
	</div>
);

export default function UserProfileDropdown(props) {
	const { handleLogout, handleTranslate, userData } = props;
	
	const profileImage = (userData.profileImage !== '') ? API_PATH + userData.profileImage : Img_upload_placeholder;

	return (
		<div style={{ marginLeft: '10px' }}>
			<Dropdown trigger={trigger(userData.fullName, profileImage)} pointing="top right" fluid inline openOnFocus={true}>
				<Dropdown.Menu>
					<Dropdown.Header className="wd-250">
						<Header as="h2" textAlign="center" onClick={props.modalOpen}>
							<Image src={profileImage} className="profile-image" />
							<Header.Subheader>{userData.fullName}</Header.Subheader>
						</Header>
					</Dropdown.Header>
					<Dropdown.Divider />
					<Dropdown.Header>
						<List>
							<List.Item>
								<List.Content className="profile-content" >
									Role: <span className="text">{userData.roleName} </span>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Content className="profile-content">
									Outlet:<span className="text">{userData.outletName}</span>
								</List.Content>
							</List.Item>
							<List.Item>
								<List.Content className="profile-content">
									Server Date:<span className="text">{userData.serverDate}</span>
								</List.Content>
							</List.Item>
						</List>
					</Dropdown.Header>
					<Dropdown.Divider className="last-divider" />

					<Dropdown.Item onClick={handleLogout}>
						<div className="logout">
							<Icon name="sign out" size="large" />Log Out
						</div>
					</Dropdown.Item>

					<Dropdown.Item onClick={handleTranslate}>
						<div className="logout">
							<Icon name="translate" size="large" />Translate
						</div>
					</Dropdown.Item>

				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
}
