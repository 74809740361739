
import { GET_ROLES, GET_ACCESS, GET_PERMISSIONS } from '../actions/actionConstants';

const initialState = {
    roles: [],
    rolesAndAccess: [],
    permission: [],
    access: {},
};

const rolesAndAccessReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                roles: action.payload,
            };
        case GET_PERMISSIONS:
            return {
                ...state,
                permission: action.payload,
            };
        case GET_ACCESS:
            return {
                ...state,
                access: action.payload,
            };
        default:
            return state;
    }
};

export default rolesAndAccessReducer;