import {
	TROWSER_TOGGLE,
	PRINTING_MODAL_TOGGLE
} from '../actions/actionConstants';

const initialState = {

	trowserModal: {
		isOpen: false,
		componentPath: null,
		data: {},
		callback: (data) => { },
	},

	printingModal: {
		isOpen: false,
		componentPath: null,
		data: {},
		callback: (data) => { },
	},
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const globalModalReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case TROWSER_TOGGLE:
			return {
				...state,
				trowserModal: {
					isOpen: !state.trowserModal.isOpen,
					componentPath: action.payload.componentPath,
					data: action.payload.data,
					callback: action.payload.callback,
				}
			};
		case PRINTING_MODAL_TOGGLE:
			return {
				...state,
				printingModal: {
					isOpen: !state.printingModal.isOpen,
					componentPath: action.payload.componentPath,
					data: action.payload.data,
					callback: action.payload.callback,
				}
			};
		default:
			return state;
	}
};

export default globalModalReducer;
