
import { ADD_USER } from '../actions/actionConstants';
import { GET_USER } from '../actions/actionConstants';

const initialState = {
    users: []
};

const usersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                users: action.payload,
            };
        default:
            return state;
    }
};

export default usersReducer;