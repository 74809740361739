/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'semantic-ui-react';

export default function Module(props) {
	const { state, handleSubModuleClose } = props;


	if (state.modules.length > 0) {
		return (
			<>
				<i className={state.modules[state.activeModule].icon} />
				<div className="sidebar-module-content-header-title">
					{state.modules[state.activeModule].title.toUpperCase()}
				</div>
				<Button
					icon="window close outline"
					onClick={handleSubModuleClose}
					className="sidebar-module-content-header-close"
				/>
			</>
		);
	} else {
		return null;
	}
}
