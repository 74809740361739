import { ERROR_REPORTING } from '../actions/actionConstants';

/* errorTypes
close
error
warning
information
success
default 
*/

const initialState = {
	errorCode: 0,
	errorType: 'default',
	errorText: ''
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const errorReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case ERROR_REPORTING:
			return {
				...state,
				errorCode: action.payload.errorCode,
				errorType: action.payload.errorType,
				errorText: action.payload.errorText
			};
		default:
			return state;
	}
};

export default errorReducer;
