import { GET_PREFERENCES } from '../actions/actionConstants';

const initialState = {
    preferences: {},
};

const preferencesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_PREFERENCES:
            return {
                ...state,
                preferences: action.payload,
            };
        default:
            return state;
    }
};

export default preferencesReducer;
