import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDimmer = styled(Dimmer)`  
	${(props) => (props.inverted === true ? 'background-color: rgba(255, 255, 255, 0.6) !important' : '')}; 
`;

export function ModalLoader(props) {
    return (
        <StyledDimmer {...props}>
            <Loader style={{ top: "40vh" }} size='big' >
                {props.children}
            </Loader>
        </StyledDimmer>
    );
}