import { GET_PRODUCTIONS } from '../actions/actionConstants';

const initialState = {
    productions: [],
    productionsCount: 0
};
const productionsReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_PRODUCTIONS:
            return {
                ...state,
                productions: action.payload.records,
                productionsCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default productionsReducer;
