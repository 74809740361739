import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`  
    /* font-family: Poppins,sans-serif !important; */
    color: inherit !important;  

	/* border-color: ${(props) => (props.errcolor === 'true' ? '#db2828' : '#ced4da')}; */
`;

export const FormLabel = React.memo(function FormLabel(props) {
    return (
        <StyledLabel {...props} />
    );
});

