import { createStore, applyMiddleware} from 'redux';
//import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './rootReducer';

/* const logger = createLogger({
	collapsed: (getState, action, logEntry) => !logEntry.error
}); */

//const store = createStore(reducer, composeWithDevTools(applyMiddleware(logger, thunk)));
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
