import React from 'react';
//import NotificationDropdown from './notification/NotificationDropdown';
import UserProfileDropdown from './userProfile/UserProfileDropdown';
import UserPasswordChange from './userPassword/UserPasswordChange';
import './headerRight.scss';

function HeaderRight(props) {
	const { handleLogout, handleTranslate, userData } = props;
    const [modal, setModal] = React.useState({ open: false });

	//HANDLING MODEL OPEN AND CLOSE COMNANDS
    const modalOpen = () => { 
		setModal({ open: true });
	};

    const modalClose = () => { 
		setModal({ open: false });
	};

    const callback = () => { 
		
	};
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>><<<<<<<<<

	return (
		<div className="header-right-wrapper">
			{/* <NotificationDropdown /> */}

			<UserProfileDropdown 
				userData={userData} 
				handleLogout={handleLogout} 
				handleTranslate={handleTranslate} 
				modalOpen={modalOpen}
			/>

			<UserPasswordChange 
				userData={userData} 
				modal={modal} 
				modalOpen={modalOpen} 
				modalClose={modalClose} 
				callback={callback} 
			/>
		</div>
	);
}

export default HeaderRight;
