import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledLabelWrapper = styled.div`
	&& {
		position: relative;
	}
`;

const StyledLabel = styled(Label)`  
   &&{
	display: ${(props) => (props.display === 'true' ? 'inline-block' : 'none')};
	
	border-radius:1px !important;
	padding: 8px  !important;
	position:absolute !important;
	/* left: 0  !important; */
	bottom: -36px  !important;
	font-size: .85em  !important;
	z-index:1;
   }   
`;

export function ErrorLabel(props) {
	return (
		<StyledLabelWrapper>
			<StyledLabel {...props} />
		</StyledLabelWrapper>
	);
}


//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
ErrorLabel.propTypes = {
	error: PropTypes.bool,
};