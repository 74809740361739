const messages = {
	en: {
		"empty" : " ",
		"required" : "This is a required field.",
		"required.min.two" : "This is a required field and minimum of two charactors.",
		"tooShort" : "Too Short!",
		"tooLong" : "Too Long!",
		"error" : "Error",

		"login.login" : "Login",
		"login.login.in.to" : "Log in to",
		"login.username" : "User Name",
		"login.user.locked"  : "Your account is locked for a while.",
		"login.enter.username" : "Enter username...",
		"login.username.empty" : "Username is a required field.",
		"login.username.tooShort" : "Username Too Short!",
		
		"login.password" : "Password",
		"login.enter.password" : "Enter password...",
		"login.password.empty" : "Password is a required field.",
		"login.password.tooShort" : "Password Too Short!",

		"login.username.password" : "User name or password is incorrect.",
		"login.password.incorrect" : "Password is incorrect.",

		"home.reauthentication" : "Reauthentication",
		"home.reauthentication.message" : "Your session has expired, enter password to get in.",
		"home.reuthenticate" : "Authenticate",

		"sales.invoice" : "Sales Invoice",
		"receive.Payment" : "Receive Payment",

		"enter.valid.date" : "Please enter a valid date.",
		"enter.required.field" : "Please enter a value for this required field.",
		"enter.number" : "Please enter a number.",
		
		"more.than.6.characters" : "This is a required field and should be more than 6 characters.",
		"passwords.do.not.match" : "Passwords do not match.",

	},
	fr: {
		"empty" : " ",
		"required" : "Champs obligatoires",
		"tooShort" : "Trop court!",
		"tooLong" : "Trop long!",
		"error" : "Erreur",
		
		"login.login" : "S'identifier",
		"login.username" : "Nom d'utilisateur",
		"login.user.locked"  : "Votre compte est bloqué pendant un moment.",
		"login.enter.username" : "Saisissez votre nom d'utilisateur...",
		"login.username.empty" : "Le nom d'utilisateur est un champ obligatoire.",
		"login.username.tooShort" : "Nom d'utilisateur trop court!",
		
		"login.password" : "Mot de passe",
		"login.enter.password" : "Entrer le mot de passe...",
		"login.password.empty" : "Mot de passe est un champ obligatoire.",
		"login.password.tooShort" : "Mot de passe trop court!",

		"login.username.password" : "L'identifiant ou le mot de passe est incorrect.",
		"login.password.incorrect" : "Le mot de passe est incorrect.",

		"home.reauthentication" : "Nouvelle authentification",
		"home.reauthentication.message" : "Votre session a expiré, entrez le mot de passe pour entrer.",
		"home.reuthenticate" : "Authentifier",

		"sales.invoice" : "Facture de vente",
		"receive.Payment" : "Paiement reçu",
	}
};

export default messages;
