/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button } from 'semantic-ui-react';
import './tabs.scss';

export default function Tabs(props) {
	const { uiTabs, handleTabSelect, handleTabClose } = props;
	let displayState = uiTabs === undefined || uiTabs.length < 1 ? { display: 'none' } : {};

	return (
		<div className="right-pane-tabs-wrapper" style={displayState}>
			{uiTabs.map((value, index) => (
				<div className={value.active ? 'tabs active-tab' : 'tabs'} key={index}>
					<a
						href="#"
						onClick={handleTabSelect}
						data-id={index}
						data-title={value.title}
						data-module_directory_name={value.moduleDirectoryName}
						data-sub_module_directory_name={value.subModuleDirectoryName}
						data-file_name={value.fileName}
						data-active={value.active}
					>
						{value.title}
					</a>
					<Button
						onClick={handleTabClose}
						icon="times circle outline"
						circular
						className="close-tab"
						id={index}
					/>
				</div>
			))}
		</div>
	);
}
