import React, { Suspense } from 'react'
import styled from "styled-components";
import PropTypes from "prop-types";
import { ModalLoader } from "../ModalLoader";



//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/*TROWSER CONTAINER */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledOverlay = styled.div`
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    background-color: transparent;
    overflow: hidden;
    width: 100%;
    
    
    ${props => !props.open ? "bottom: 100%" : "bottom: 0"};
    ${props => !props.open ? "height: 0" : "height: 100%"};
`;//transition: .5s ease;

export function TrowserContainer(props) {
    const [ModalComponents, setModalComponents] = React.useState(null);

    /* -------------------------------------------------------*/
    React.useEffect(() => {
        if (props.componentPath !== null) {
            const ModalComponent = React.lazy(() => import(`../../modules/${props.componentPath}`));
            setModalComponents(<ModalComponent data={props.data}/>);
        } else {
            setModalComponents(null);
        }
    }, [props.componentPath, props.data]);
    /* -------------------------------------------------------*/

    /* -------------------------------------------------------*/
    const Loading = (
        <ModalLoader active={true} style={{ top: "40vh" }} inverted={true}>Loading...</ModalLoader>
    );

    return (
        <StyledOverlay open={props.isOpen} className={props.className}>
            <Suspense fallback={Loading}>
                {ModalComponents}
            </Suspense>
        </StyledOverlay>
    )
}

TrowserContainer.propTypes = {
    componentPath: PropTypes.string,
    isOpen: PropTypes.bool,
};




//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/*TROWSER HEADER */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/* CSS STYLES */
const StyledHeader = styled.div` 
    margin:0;
    padding:0;
`;
/*  MAIN FUNCTION */
export function TrowserHeader(props) {
    return (
        <StyledHeader {...props} className={props.className}>
            {props.children}
        </StyledHeader>
    )
}

TrowserHeader.propTypes = {
    className: PropTypes.string,
};




//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/*TROWSER BODY */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/* CSS STYLES */
const StyledBody = styled.div` 
    margin:0;
    padding:0;
    width:100%;

    height: calc(100vh - 13em);
    overflow: auto; /* Hide horizontal scrollbar */
    overflow-y: auto; 
    scrollbar-color: #cccccc #e9ecef;
    scrollbar-width: thin;
`;
/*  MAIN FUNCTION */
export const TrowserBody = React.forwardRef((props, ref) => {
    return (
        <StyledBody {...props} className={props.className} ref={ref}>
            {props.children}
        </StyledBody>
    )
})

TrowserFooter.TrowserBody = {
    className: PropTypes.string,
};




//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/*TROWSER FOOTER */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/* CSS STYLES */
const StyledFooter = styled.div` 
    background:#1d2939;
    margin:0;
    padding:8px 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
`;
/*  MAIN FUNCTION */
export function TrowserFooter(props) {

    return (
        <StyledFooter {...props} className={props.className}>
            {props.children}
        </StyledFooter>
    )
}

TrowserFooter.propTypes = {
    className: PropTypes.string,
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<