import axios from 'axios';
import {
	USER_INTERFACE,
	GLOBAL_LOADER,
	SNACKBAR_OPEN,
	SNACKBAR_CLOSE,
	REAUTHENTICATE_TOGGLE,
	REAUTHENTICATE_LOADER_TOGGLE,
	REAUTHENTICATE_ERROR
} from './actionConstants';
import { URL } from '../../utils/url'

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const receiveUI = (uiDetails) => ({
	type: USER_INTERFACE,
	payload: uiDetails
});

export const uiLoading = (state) => ({
	type: GLOBAL_LOADER,
	payload: state
});

export const globalLoader = (state) => (dispatch) => {
	/*Dispatch loading first */
	dispatch(uiLoading(state));
};

export const getUserInterface = () => (dispatch) => {
	/*Dispatch loading first */
	dispatch(uiLoading(true));
	/*Fetching the UI from ser and Dispatching the action */
	return axios({
		method: 'get',
		url: `${URL}/api/v1/index.php/uI`,
		headers: {
			'Content-type': 'application/json'
		},
		data: {}
	});
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const snackbarOpen = (state) => ({
	type: SNACKBAR_OPEN,
	payload: state
});

export const snackbarClose = () => ({
	type: SNACKBAR_CLOSE
});

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const reauthenticateToggle = () => ({
	type: REAUTHENTICATE_TOGGLE
});

export const reauthenticateLoaderToggle = () => ({
	type: REAUTHENTICATE_LOADER_TOGGLE,
});

export const reauthenticateError = (errorType) => ({
	type: REAUTHENTICATE_ERROR,
	payload: errorType
});

export const reauthenticateUser = (credentials) => (dispatch) => {
	dispatch(reauthenticateLoaderToggle());
	/*Fetching the UI from ser and Dispatching the action */
	return axios({
		method: 'post',
		url: `${URL}/api/v1/index.php/reauthenticate`,
		timeout: 1000 * 5, // Wait for 5 seconds
		headers: {
			'Content-type': 'application/json'
		},
		data: credentials
	});
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
