import { 
    GET_EXPENSES 
    } from '../actions/actionConstants';

const initialState = {
    expenses: [],
    expensesCount: 0,
};

const expensesReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_EXPENSES:
            return {
                ...state,
                expenses:  action.payload.records,
                expensesCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default expensesReducer;
