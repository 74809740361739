import {
    GET_SALES_ORDER,
} from '../actions/actionConstants';


const initialState = {
    salesOrder: [],
    salesOrderCount: 0,
};

const salesOrderReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_SALES_ORDER:
            return {
                ...state,
                salesOrder: action.payload.records,
                salesOrderCount: action.payload.count,
            };
        default:
            return state;
    }
};

export default salesOrderReducer;
