import { GET_WAREHOUSES } from '../actions/actionConstants';

const initialState = {
    warehouses: [],
};

const warehousesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.payload,
            };
        default:
            return state;
    }
};

export default warehousesReducer;
