
import appReducer from './reducers/appReducer';

//Making sure the state/store is totally cleared/empty when user logs out
const rootReducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		state = undefined;

		//Clearing localstorage
		localStorage.clear();
	}

	return appReducer(state, action);
};

export default rootReducer;
