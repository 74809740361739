import { GET_CUSTOMER_CATEGORY, GET_CUSTOMERS, CHECKED_CUSTOMER_LIST } from '../actions/actionConstants';

const initialState = {
    customerCategories: [],
    customers: [],
    customersCount: 0,
};
const customersReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_CUSTOMER_CATEGORY:
            return {
                ...state,
                customerCategories: action.payload,
            };

        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.records,
                customersCount: action.payload.count,
            };

        case CHECKED_CUSTOMER_LIST:
            const { type, index, checked } = action.payload;

            if (type === 'check_single') {
                const customerList = state.customers;
                customerList[index] = { ...customerList[index], checked: checked };

                return { ...state, customers: customerList };
            }

            if (type === 'check_all') {
                const customerList = state.customers.map((obj, index) => {
                    return { ...obj, checked: checked }
                })
                return { ...state, customers: customerList };
            }

            return state;

        default:
            return state;
    }
};

export default customersReducer;
