import React, { Component } from 'react';
import { Header, Icon, Button } from 'semantic-ui-react'


class ErrorBoundary extends Component {
	state = { hasError: false };

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		console.log(JSON.stringify(error + ' | ' + info, 4, null));
	}

	handleBrowserRefresh() {
		//Forces the web page to reload from the server, bypassing the cache
		window.location.reload(true);
	}


	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div style={{
					height: '90vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<Header as='h2' icon textAlign='center'>
						<Icon name='warning sign' />
						<Header.Content>
							We're sorry — something's gone wrong.
						</Header.Content>

						<Header.Subheader
							style={{
								marginTop: '5px',
							}}
						>
							Please contact the IT team or try refreshing the browser.
						</Header.Subheader>

						<Button negative
							onClick={this.handleBrowserRefresh}
							style={{
								marginTop: '10px',
							}}
						>
							Click to refresh browser.
						</Button>
					</Header>
				</div>
			);
		}

		return this.props.children;
	}
}
export default ErrorBoundary;
