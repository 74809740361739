import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Form, Icon, Divider, Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Input } from '../../components/Input';
import { ErrorLabel } from '../../components/ErrorLabel';


const valSchema = Yup.object().shape({
	username: Yup.string().min(2, 'login.username.tooShort').max(50).required('login.username.empty'),
	password: Yup.string().min(4, 'login.password.tooShort').required('login.password.empty')
});


function LoginForm(props) {
	const { handleLogin, loading } = props;
	const initialFormValues = { username: '', password: '' };


	return (
		<Formik
			initialValues={initialFormValues}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				handleLogin(values);
				resetForm(initialFormValues);
				setSubmitting(false);
			}}
			validationSchema={valSchema}
		>
			{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
				<Form className="form" onSubmit={handleSubmit} loading={loading}>
					<Form.Field>
						<label>
							<FormattedMessage id="login.username" defaultMessage="User Name" />
						</label>
						<FormattedMessage id="login.enter.username" defaultMessage="Enter username...">
							{(placeholder) => (
								<Input
									name="username"
									type="text"
									icon="user"
									iconPosition="left"
									size="small"
									placeholder={placeholder}
									value={values.username}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.username && touched.username ? true : false}
								/>
							)}
						</FormattedMessage>
						<ErrorLabel
							size="small"
							color="red"
							pointing
							display={errors.username && touched.username ? 'true' : 'false'}
						>
							<Icon name="warning" />
							<FormattedMessage id={errors.username ? errors.username : 'empty'} />
						</ErrorLabel>
					</Form.Field>
					<Form.Field>
						<label>
							<FormattedMessage id="login.password" defaultMessage="Password" />
						</label>
						<FormattedMessage id="login.enter.password" defaultMessage="Enter password...">
							{(placeholder) => (
								<Input
									name="password"
									type="password"
									icon="lock"
									iconPosition="left"
									size="small"
									placeholder={placeholder}
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.password && touched.password ? true : false}
								/>
							)}
						</FormattedMessage>
						<ErrorLabel
							size="small"
							color="red"
							pointing
							display={errors.password && touched.password ? 'true' : 'false'}
						>
							<Icon name="warning" />
							<FormattedMessage id={errors.password ? errors.password : 'empty'} />
						</ErrorLabel>
					</Form.Field>

					<Divider hidden />

					{/* <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
						<FormattedMessage id="login.login" defaultMessage="Login" />
					</Button> */}

					<Button type="submit" fluid primary disabled={isSubmitting}>
						<FormattedMessage id="login.login" defaultMessage="Login" />
					</Button>
				</Form>
			)}
		</Formik>
	);
}

LoginForm.propTypes = {
	handleLogin: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired
};

export default LoginForm;
