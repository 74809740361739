import { GET_TAX } from '../actions/actionConstants';

const initialState = {
    taxes: [],
};

const taxesReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_TAX:
            return {
                ...state,
                taxes: action.payload,
            };
        default:
            return state;
    }
};

export default taxesReducer;
