import {
	USER_INTERFACE,
	GLOBAL_LOADER,
	SNACKBAR_OPEN,
	SNACKBAR_CLOSE,
	REAUTHENTICATE_TOGGLE,
	REAUTHENTICATE_LOADER_TOGGLE,
	REAUTHENTICATE_ERROR
} from '../actions/actionConstants';

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const initialState = {
	userData: {},
	uiModules: [],
	uiSnackbar: {
		sbState: false,
		sbType: 'default',
		sbMessage: '...'
	},
	uiReauthencation: {
		isOpen: false,
		isLoading: false,
		error: false,
		errorType: false //false: password error. True: account locked for a while
	},
	uiLoader: true
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

const uiReducer = (state = initialState, action = {}) => {
	switch (action.type) {

		case USER_INTERFACE:
			return {
				...state,
				userData: action.payload.userData,
				uiModules: action.payload.uiModules,
				uiLoader: false,
			};

		case SNACKBAR_OPEN:
			return {
				...state,
				uiSnackbar: action.payload,
			};

		case SNACKBAR_CLOSE:
			const uiSnackbar = {
				...state.uiSnackbar,
				sbState: false,
			};

			return {
				...state,
				uiSnackbar: uiSnackbar,
			};

		case GLOBAL_LOADER:
			return {
				...state,
				uiLoader: (action.payload) ? true : false,
			};

		case REAUTHENTICATE_TOGGLE:
			const uiReauthencation = {
				...state.uiReauthencation,
				isOpen: !state.uiReauthencation.isOpen,
			};
			return {
				...state,
				uiReauthencation: uiReauthencation,
			};

		case REAUTHENTICATE_LOADER_TOGGLE:
			const uiReauthencationIsLoading = {
				...state.uiReauthencation,
				isLoading: !state.uiReauthencation.isLoading,
			};
			return {
				...state,
				uiReauthencation: uiReauthencationIsLoading,
			};

		case REAUTHENTICATE_ERROR:
			const uiReauthencationError = {
				...state.uiReauthencation,
				isLoading: action.payload.isLoading,
				error: action.payload.error,
				errorType: action.payload.errorType,
			};
			return {
				...state,
				uiReauthencation: uiReauthencationError,
			};

		default:
			return state;
	}
};

export default uiReducer;
