import { 
    GET_DEBITS,
    GET_DEBIT_NOTES,
    } from '../actions/actionConstants';

const initialState = {
    debits: [],
    debitsCount: 0,
    debitNotes: [],
    debitNotesCount: 0,
};

const debitsReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_DEBITS:
            return {
                ...state,
                debits:  action.payload.records,
                debitsCount: action.payload.count,
            };

        case GET_DEBIT_NOTES:
            return {
                ...state,
                debitNotes:  action.payload.records,
                debitNotesCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default debitsReducer;
