import { USER_LOGEDIN } from '../actions/actionConstants';


const loginOutReducer = (state = {}, { type, loginData }) => {
	switch (type) {
		case USER_LOGEDIN:
			return {...state, loginInitialization: loginData };
		default:
			return state;
	}
};

export default loginOutReducer;
