import {
    GET_PROFORMA_INVOICE,
} from '../actions/actionConstants';


const initialState = {
    proformaInvoice: [],
    proformaInvoiceCount: 0,
};

const proformaInvoiceReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_PROFORMA_INVOICE:
            
            return {
                ...state,
                proformaInvoice: action.payload.records,
                proformaInvoiceCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default proformaInvoiceReducer;
