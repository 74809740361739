import { 
    GET_PURCHASE_ORDERS,
    } from '../actions/actionConstants';

const initialState = {
    purchaseOrders: [],
    purchaseOrdersCount: 0,
};

const purchaseOrderReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_PURCHASE_ORDERS:

            return {
                ...state,
                purchaseOrders:  action.payload.records,
                purchaseOrdersCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default purchaseOrderReducer;
