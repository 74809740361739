import { GET_JOURNAL_ENTRIES   } from '../actions/actionConstants';

const initialState = {
    journalEntries: [],
    journalEntriesCount: 0,
};
const journalEntriesReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_JOURNAL_ENTRIES :

            return {
                ...state,
                journalEntries: action.payload.records,
                journalEntriesCount: action.payload.count,
            };
        default:
            return state;
    }
};

export default journalEntriesReducer;
