import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Image, Divider, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import sha512 from 'sha512';
import { connect } from 'react-redux';
import { login, logedIn } from '../../redux/actions/logInOutAction';
import { SUCCESS, BAD_REQUEST, INVALID_CREDENTIALS } from '../../utils/genericConstants';
import LoginForm from './LoginForm';
import './Login.css';
import Logo from '../../images/logo_lg.png';

function LoginContainer(props) {
	const { history, login, logedIn } = props;
	const [formState, setFormState] = useState({ loading: false, error: false, errorType: false });

	const dismissErrorMessage = () => {
		setFormState({ ...formState, error: false });
	};

	const userAuthenticated = (response) => {
		//React router history from props. Redirect to home page if user is authenticated
		logedIn(response.data);
		if (response.status === SUCCESS) history.replace(`/home/`);
	};

	const handleLogin = (credentials) => {
		//redux action
		login({
			username: credentials.username,
			password: sha512(credentials.password).toString('hex')
		})
			.then((response) => userAuthenticated(response))
			.catch((error) => handleError(error));

		setFormState({ ...formState, loading: true });
	};

	const handleError = (error) => {
		if (error.response.status === INVALID_CREDENTIALS) {
			//Account is locked for a while. Code: 422
			setFormState({ loading: false, error: true, errorType: false });
		} else if (error.response.status === BAD_REQUEST) {
			//Password or username is invalid.
			setFormState({ loading: false, error: true, errorType: true });
		} else {
			setFormState({ loading: false });
		}

		console.error(`Login Error: ${error.response.status} - ${error.response.statusText}`);
	};

	return (
		<div className="wrapper">
			<div className="form-container">
				<Header as="h2" icon textAlign="center">
					<Image src={Logo} className="login-logo" />
					<Divider horizontal>
						<Header.Content>
							Log in to <span style={{ color: '#dfa612' }}>Smart ABK</span>
						</Header.Content>
					</Divider>
				</Header>
				<Message
					onDismiss={dismissErrorMessage}
					error
					size="mini"
					style={{ display: formState.error ? 'block' : 'none' }}
				>
					<Message.Header>
						<FormattedMessage id="error" defaultMessage="Error" />:
					</Message.Header>
					<p>
						{formState.errorType === false ? (
							<FormattedMessage
								id="login.username.password"
								defaultMessage="User name or password is incorrect."
							/>
						) : (
								<FormattedMessage id="login.user.locked" defaultMessage="Account is locked for a while." />
							)}
					</p>
				</Message>

				<LoginForm handleLogin={handleLogin} loading={formState.loading} />
			</div>
		</div>
	);
}

LoginContainer.propTypes = {
	history: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	logedIn: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		loginState: state.logInOutReducer.loginState
	};
};
const mapDispatchToProps = {
	login,
	logedIn
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
