import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import sha512 from "sha512";

import {
  getUserInterface,
  receiveUI,
  snackbarOpen,
  snackbarClose,
  reauthenticateToggle,
  reauthenticateUser,
  reauthenticateLoaderToggle,
  reauthenticateError
} from "../../redux/actions/uiAction";
import { selectSubModule } from "../../redux/actions/uiTabsAction";


import {
  SUCCESS,
  BAD_REQUEST,
  UNAUTHORISED,
  FORBIDDEN,
  INVALID_CREDENTIALS,
  MOBILE_SIZE
} from "../../utils/genericConstants";

import * as Wrappers from "../../components/layout/Wrappers";
import LeftPaneContainer from "./bodyContent/leftPane/LeftPaneContainer";
import RightPaneContainer from "./bodyContent/rightPane/RightPaneContainer";
import HeaderContainer from "./headerContent/HeaderContainer";

import Snackbar from "../../components/Snackbar";
import Reauthenticate from "../../components/Reauthenticate";

import {
  TrowserContainer
} from "../../components/layout/Trowser";

import {
  PrintingContainer
} from "../../components/layout/Printing";

import {
  ConnectionStatus
} from "../../components/ConnectionStatus";

import {
  ModalLoader
} from "../../components/ModalLoader";

import {
  getPreferences,
  gettingPreferences,
} from "../../redux/actions/preferencesAction";

import { 
  logout 
} from '../../redux/actions/logInOutAction';

import { URL } from '../../utils/url'




//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
function HomeContainer(props) {
  //Constat variables
  const REQUEST_INTERVAL = 5000;
  
  const { uiLoader, uiModules, getUserInterface, receiveUI, selectSubModule, history } = props;
  const { uiSnackbar, snackbarOpen, snackbarClose } = props;
  const { uiReauthencation, reauthenticateToggle, reauthenticateUser, reauthenticateLoaderToggle, reauthenticateError } = props;
  const { globalModal } = props;


  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const [state, setState] = React.useState({
    mobileMenuState: false,
    loader: true
  });

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const [moduleState, setModuleState] = React.useState({
    showTooltip: true,
    overlaySidebarOpen: false,
    subModuleOpen: false,
    activeModule: 0,
    modules: []
  });

  //<<<<<<<<<<<<<<<<<<<<<<<<<<< GETTING SYSTEM PREFERENCE SETTINGS <<<<<<<<<<<<<<<<<<<<<<<<<<<
  React.useEffect(() => {
    props.getPreferences('').then((response) => {
      if (response.status === SUCCESS) {
        props.gettingPreferences(response.data);
      }
    }).catch(error => {
      try {
        handleError(error)
      } catch (e) {
        console.log(e.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  React.useEffect(() => {
    getUserInterface()
      .then(response => receiveUI(response.data))
      .catch(error => handleError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setModuleState({
      ...moduleState,
      modules: uiModules
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiModules]);
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const [connectionState, setConnectionState] = React.useState(false);

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  React.useEffect(() => {
    var xhr = new XMLHttpRequest();

    let intervalID = setInterval(() => {
       xhr.open('HEAD', `${URL}/api/v1/index.php`, true);//TODO if there is internet 
       xhr.send();
     }, REQUEST_INTERVAL);

    xhr.addEventListener("readystatechange", processRequest, false);

    function processRequest(e) {
      if (xhr.readyState === 4) {
        //If you use a cache storage manager (service worker), it is likely that the
        //index.php file will be available even without internet, so do the following validation
        if (xhr.status >= 200 && xhr.status < 304) {
          //console.log('On line!');
          setConnectionState(false)
        } else {
          //console.log('Offline');
          setConnectionState(true)
        }
      }
    }

    return () => {
      clearInterval(intervalID);
    };
  }, [])
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleModuleClick = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;
    const nextModule = [...moduleState.modules];
    nextModule[moduleState.activeModule].active = false;
    nextModule[id].active = true;
    setModuleState({
      ...moduleState,
      activeModule: id,
      subModuleOpen: true,
      modules: nextModule
    });
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleSubModuleClose = (e) => {
    e.preventDefault();
    setModuleState({
      ...moduleState,
      subModuleOpen: false
    });
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleSubModuleSelect = (e) => {
    e.preventDefault();
    setModuleState({ ...moduleState, subModuleOpen: false });
    //If sub modules are clicked in mobile mode, the left pane display should be none
    let windowWidth = window.innerWidth || document.documentElement.clientWidth;
    if (windowWidth <= MOBILE_SIZE) {
      /* Is within mobile viewport */
      setState({ ...state, mobileMenuState: !state.mobileMenuState });
    }
    /* selecting a sub module or resource to add to the tab and make it active */
    selectSubModule(e.target.dataset);
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleMobileMenu = React.useCallback(() => {
    setState({ ...state, mobileMenuState: !state.mobileMenuState });
  }, []);
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    snackbarClose();
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<GLOBAL RE<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleReauthencation = (credentials) => {
    reauthenticateUser({
      username: credentials.username,
      password: sha512(credentials.password).toString("hex")
    })
      .then(response => {
        if (response.status === SUCCESS) {
          reauthenticateToggle();
          reauthenticateLoaderToggle();

          reauthenticateError({
            isOpen: false,
            isLoading: false,
            error: false,
            errorType: false
          });
        }
      })
      .catch(error => handleError(error));
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  
  const handleLogout = () => {
    //Logging out users from the app.
		props.logout().then((response) => {
			//logedOut();//TODO: clearing all object in the browser after logout issue
			if (response.status === SUCCESS) {
        history.push(`/`);
      }
		}).catch((error) => {
			handleError(error);
		});
	};

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  const handleError = error => {
    if (error.response.status === UNAUTHORISED) {
      //Unauthorised: User is not authenticated. Expired cookie redirect to login  Code: 401
      history.replace(`/`);
    } else if (error.response.status === FORBIDDEN) {
      //Forbidden: Token expired redirect to login  Code: 403
      history.replace(`/`);
    } else if (error.response.status === INVALID_CREDENTIALS) {
      //Account is locked for a while. Code: 422
      reauthenticateError({ isLoading: false, error: true, errorType: false });
      //Switching the error type on the reauthenticaion interface
    } else if (error.response.status === BAD_REQUEST) {
      //Password is invalid. Code: 400
      reauthenticateError({ isLoading: false, error: true, errorType: true });
      //Switching the error type on the re-authenticaion interface
    } else {
      snackbarOpen({
        sbState: true,
        sbType: "error",
        sbMessage: error.response.statusText
      });
    }
    console.log(
      `Home Page Error: ${error.response.status} - ${error.response.statusText}`
    );
  };


  return (
    <>
      {/* 
       ////////////////////////////////////////////////////////////////////////////// 
        LAYOUTS   
       ////////////////////////////////////////////////////////////////////////////// 
       */}

      {/* Connection status is for checking and displaying if there is internet or not */}
      <ConnectionStatus state={connectionState}/>

      <HeaderContainer
        mobileMenuState={state.mobileMenuState}
        handleMobileMenu={handleMobileMenu}
        handleLogout={handleLogout} 
        history={props.history}
      />

      <Wrappers.BodyContainer className="body-wrapper">
        {/* For the left sidebar and main menus */}
        <LeftPaneContainer
          handleModuleClick={handleModuleClick}
          handleSubModuleClose={handleSubModuleClose}
          handleSubModuleSelect={handleSubModuleSelect}
          mobileMenuState={state.mobileMenuState}
          data={moduleState}
          handleLogout={handleLogout} 
        />
        {/* The right workable area */}
        <RightPaneContainer
          uiTab={props.uiTab}
          uiTabSelect={props.uiTabSelect}
          uiTabClose={props.uiTabClose}
          history={props.history}
        /*subModuleOpen={moduleState.subModuleOpen}*/
        />
      </Wrappers.BodyContainer>


      {/* 
       //////////////////////////////////////////////////////////////////////////////      
        COMMON MODAL WINDOWS   
       ////////////////////////////////////////////////////////////////////////////// 
       */}
      <ModalLoader active={uiLoader} style={{ zIndex: '2000' }} inverted={true}>
        Loading...
      </ModalLoader>

      <Reauthenticate
        isOpen={uiReauthencation.isOpen}
        isLoading={uiReauthencation.isLoading}
        error={uiReauthencation.error}
        errorType={uiReauthencation.errorType}
        username={props.userData.username}
        submit={handleReauthencation}
      />

      <Snackbar
        sbClose={handleSnackbarClose}
        sbState={uiSnackbar.sbState}
        sbType={uiSnackbar.sbType}
        sbMessage={uiSnackbar.sbMessage}
      />

      <TrowserContainer
        isOpen={globalModal.trowserModal.isOpen}
        componentPath={globalModal.trowserModal.componentPath}
        data={globalModal.trowserModal.data}
        callback={globalModal.trowserModal.callback}
      />

      <PrintingContainer
        isOpen={globalModal.printingModal.isOpen}
        componentPath={globalModal.printingModal.componentPath}
        data={globalModal.printingModal.data}
        callback={globalModal.printingModal.callback}
      />


    </>
  );
}
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
HomeContainer.propTypes = {
  userData: PropTypes.object.isRequired,
  uiLoader: PropTypes.bool.isRequired,
  uiModules: PropTypes.array,
  uiSnackbar: PropTypes.shape({
    sbState: PropTypes.bool.isRequired,
    sbType: PropTypes.string,
    sbMessage: PropTypes.oneOfType([
      PropTypes.string, 
      PropTypes.number, 
      PropTypes.array
    ])
  }),
  uiReauthencation: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorType: PropTypes.bool.isRequired
  }),
  getUserInterface: PropTypes.func.isRequired,
  selectSubModule: PropTypes.func.isRequired,
  receiveUI: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  snackbarOpen: PropTypes.func.isRequired,
  snackbarClose: PropTypes.func.isRequired,
  reauthenticateToggle: PropTypes.func.isRequired,
  reauthenticateUser: PropTypes.func.isRequired,
  reauthenticateLoaderToggle: PropTypes.func.isRequired,
  reauthenticateError: PropTypes.func.isRequired
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const mapStateToProps = state => {
  return {
    userData: state.uiReducer.userData,
    uiLoader: state.uiReducer.uiLoader,
    uiModules: state.uiReducer.uiModules,
    uiSnackbar: state.uiReducer.uiSnackbar,
    uiReauthencation: state.uiReducer.uiReauthencation,
    globalModal: state.globalModalReducer,
  };
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const mapDispatchToProps = {
  getUserInterface,
  selectSubModule,
  receiveUI,
  snackbarOpen,
  snackbarClose,
  reauthenticateToggle,
  reauthenticateUser,
  reauthenticateLoaderToggle,
  reauthenticateError,

  getPreferences,
  gettingPreferences,

  logout,
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
