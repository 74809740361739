import {
    GET_PRODUCT_AND_SERVICE_CATEGORIES,
    GET_GLOBAL_PRODUCT_AND_SERVICE_NAMES,
    GET_PRODUCTS_AND_SERVICES,
    CHECKED_PRODUCTS_AND_SERVICES_LIST,
    GET_PRODUCTS_AND_SERVICES_PRICES,
} from '../actions/actionConstants';





const initialState = {
    productAndServiceGlobalNames: [],
    productAndServiceCategories: [],
    productsAndServices: [],
    productsAndServicesCount: 0,
    productsAndServicesPrices: [],
};
const productAndServiceReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_GLOBAL_PRODUCT_AND_SERVICE_NAMES:
            return {
                ...state,
                productAndServiceGlobalNames: action.payload,
            };

        case GET_PRODUCT_AND_SERVICE_CATEGORIES:
            return {
                ...state,
                productAndServiceCategories: action.payload,
            };

        case GET_PRODUCTS_AND_SERVICES:
            return {
                ...state,
                productsAndServices: action.payload.records,
                productsAndServicesCount: action.payload.count,
            };

        case CHECKED_PRODUCTS_AND_SERVICES_LIST:
            const { type, index, checked } = action.payload;

            if (type === 'check_single') {
                const productsAndServicesList = state.productsAndServices;
                productsAndServicesList[index] = {
                    ...productsAndServicesList[index],
                    checked: checked
                };

                return {
                    ...state,
                    productsAndServices: productsAndServicesList,
                };
            }

            if (type === 'check_all') {
                const productsAndServicesList = state.productsAndServices.map((obj, index) => {
                    return { ...obj, checked: checked }
                })
                return {
                    ...state,
                    productsAndServices: productsAndServicesList,
                };
            }
            return state;
        //PRODUCT AND SERVICE PRICES

        case GET_PRODUCTS_AND_SERVICES_PRICES:
            return {
                ...state,
                productsAndServicesPrices: action.payload,
            };
        default:
            return state;
    }
};

export default productAndServiceReducer;
