import { 
	SELECT_SUBMODULE, 
	SELECT_UI_TAB, 
	CLOSE_UI_TAB 
} from '../actions/actionConstants';

const initialState = {
	uiTabs: []
};
const uiTabsReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case SELECT_SUBMODULE:
			let itExist = false;

			let tabs = state.uiTabs.map((obj, index) => {
				if (obj.title === action.payload.title) {
					itExist = true;
					return {
						title: obj.title,
						moduleDirectoryName: obj.moduleDirectoryName,
						subModuleDirectoryName: obj.subModuleDirectoryName,
						fileName: obj.fileName,
						active: true
					};
				} else {
					return {
						title: obj.title,
						moduleDirectoryName: obj.moduleDirectoryName,
						subModuleDirectoryName: obj.subModuleDirectoryName,
						fileName: obj.fileName,
						active: false
					};
				}
			});

			if (itExist) {
				return { 
					...state, 
					uiTabs: tabs 
				};
			} else {
				let tabs_ = tabs.concat({
					title: action.payload.title,
					moduleDirectoryName: action.payload.module_directory_name,
					subModuleDirectoryName: action.payload.sub_module_directory_name,
					fileName: action.payload.file_name,
					active: true
				});
				return { 
					...state, 
					uiTabs: tabs_ 
				};
			}

		case SELECT_UI_TAB:
			const tabs_ = state.uiTabs.map((obj, index) => {
				if (obj.title === action.payload.title) {
					return {
						title: obj.title,
						moduleDirectoryName: obj.moduleDirectoryName,
						subModuleDirectoryName: obj.subModuleDirectoryName,
						fileName: obj.fileName,
						active: true
					};
				} else {
					return {
						title: obj.title,
						moduleDirectoryName: obj.moduleDirectoryName,
						subModuleDirectoryName: obj.subModuleDirectoryName,
						fileName: obj.fileName,
						active: false
					};
				}
			});
			return { ...state, uiTabs: tabs_ };

		case CLOSE_UI_TAB:
			let new_state = { 
				...state, 
				uiTabs: [] 
			};
			//If an active tab is closed shift ativeness to the closest backward tab
			if (state.uiTabs[action.payload].active) {
				const filteredTabsArray = state.uiTabs.filter((obj, index) => {
					return index !== parseInt(action.payload);
				});
				//Setting the closest active tab
				const index = filteredTabsArray.length - 1;
				if (index > -1) filteredTabsArray[index].active = true;
				//Setting new state
				new_state = { 
					...state, 
					uiTabs: filteredTabsArray 
				};
			} else {
				//If an unactive tab is close
				const filteredTabsArray = state.uiTabs.filter((obj, index) => {
					return index !== parseInt(action.payload);
				});
				//Setting new state
				new_state = { 
					...state, 
					uiTabs: filteredTabsArray 
				};
			}


			//Removing the closed tab local storage content
			for(let index = 0; index < localStorage.length; index++){
				const storage_key = localStorage.key(index);
				const storage_head_key = storage_key.split('--')[0];
				
				if(storage_head_key ===  state.uiTabs[action.payload].subModuleDirectoryName){
					//Removing the content
					localStorage.removeItem(storage_key);
				}
			}
			
			//Setting new state
			return new_state;

		default:
			return state;
	}
};

export default uiTabsReducer;
