import axios from 'axios';
import { URL } from '../../utils/url'
import { ADD_USER, GET_USER } from './actionConstants';


//<<<<<<<<<<<<<<<<<<<<<CUSTOMERS ACTIONS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const addingUsers = (result) => ({
	type: ADD_USER,
	payload: result
});

export const gettingUsers = (result) => ({
	type: GET_USER,
	payload: result
});
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const getUsers = (param) => (dispatch) => {
	return axios({
		method: 'get',
		url: `${URL}/api/v1/index.php/Users/?${param}`,
		headers: {
			'Content-type': 'application/json'
		},
		data: {}
	});
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const addUser = (formData) => (dispatch) => {
	return axios({
		method: 'post',
		url: `${URL}/api/v1/index.php/Users`,
		headers: {
			'Content-type': 'multipart/form-data'
		},
		data: formData
	});
};

export const editUser = (formData) => (dispatch) => {
	return axios({
		method: 'post',
		url: `${URL}/api/v1/index.php/Users`,
		headers: {
			'Content-type': 'multipart/form-data'
		},
		data: formData
	});
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const changeUserStatus = (param) => (dispatch) => {
	return axios({
		method: 'patch',
		url: `${URL}/api/v1/index.php/Users`,
		headers: {
			'Content-type': 'application/json'
		},
		data: JSON.stringify(param)
	});
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

export const changePassword = (param) => (dispatch) => {
    return axios({
        method: 'put',
        url: `${URL}/api/v1/index.php/Users`,
        headers: {
            'Content-type': 'application/json'
        },
        data: JSON.stringify(param)
    });
};