import axios from 'axios';
import {
    ADD_PREFERENCES,
    GET_PREFERENCES,
} from './actionConstants';
import { URL } from '../../utils/url'




//<<<<<<<<<<<<<<<<<<<<<CUSTOMER CATEGORIES ACTIONS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export const addingPreferences = (result) => ({
    type: ADD_PREFERENCES,
    payload: result
});

export const gettingPreferences = (result) => ({
    type: GET_PREFERENCES,
    payload: result
});




export const getPreferences = (param) => (dispatch) => {
    return axios({
        method: 'get',
        url: `${URL}/api/v1/index.php/Preferences/?${param}`,
        headers: {
            'Content-type': 'application/json'
        },
        data: {}
    });
};

export const editPreferences = (param) => (dispatch) => {
    return axios({
        method: 'put',
        url: `${URL}/api/v1/index.php/Preferences`,
        headers: {
            'Content-type': 'application/json'
        },
        data: JSON.stringify(param)
    });
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
