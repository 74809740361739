export const USER_LOGEDIN = 'USER_LOGEDIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_INTERFACE = 'USER_INTERFACE';
export const GLOBAL_LOADER = 'GLOBAL_LOADER';

export const SELECT_SUBMODULE = 'SELECT_SUBMODULE';
export const SELECT_UI_TAB = 'SELECT_UI_TAB';
export const CLOSE_UI_TAB = 'CLOSE_UI_TAB';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export const REAUTHENTICATE_TOGGLE = 'REAUTHENTICATE_TOGGLE';
export const REAUTHENTICATE_LOADER_TOGGLE = 'REAUTHENTICATE_LOADER_TOGGLE';
export const REAUTHENTICATE_ERROR = 'REAUTHENTICATE_ERROR';

export const ERROR_REPORTING = 'ERROR_REPORTING';
export const TROWSER_TOGGLE = 'TROWSER_TOGGLE';
export const PRINTING_MODAL_TOGGLE = 'PRINTING_MODAL_TOGGLE';

export const GET_CUSTOMER_CATEGORY = 'GET_CUSTOMER_CATEGORY';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const ADD_CUSTOMER_CATEGORY = 'ADD_CUSTOMER_CATEGORY';
export const ADD_CUSTOMERS = 'ADD_CUSTOMERS';
export const CHECKED_CUSTOMER_LIST = 'CHECKED_CUSTOMER_LIST';

export const GET_SUPPLIER_CATEGORY = 'GET_SUPPLIER_CATEGORY';
export const ADD_SUPPLIERS = 'ADD_SUPPLIERS';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const CHECKED_SUPPLIER_LIST = 'CHECKED_SUPPLIER_LIST';
export const GET_SUPPLIER_PAYMENT  = 'GET_SUPPLIER_PAYMENT';

export const ADD_OUTLET = 'ADD_OUTLET';
export const GET_OUTLET = 'GET_OUTLET';

export const ADD_USER = 'ADD_USER';
export const GET_USER = 'GET_USER';

export const ADD_ROLES = 'ADD_ROLES';
export const GET_ROLES = 'GET_ROLES';
export const GET_ACCESS = 'GET_ACCESS';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
/* export const ADD_ROLES_AND_ACCESS = 'ADD_ROLES_AND_ACCESS';
export const GET_ROLES_AND_ACCESS = 'GET_ROLES_AND_ACCESS'; */

export const ADD_PRODUCT_AND_SERVICE_CATEGORY = 'ADD_PRODUCT_AND_SERVICE_CATEGORY'
export const ADD_PRODUCTS_AND_SERVICES = 'ADD_PRODUCTS_AND_SERVICES'
export const GET_PRODUCT_AND_SERVICE_CATEGORIES = 'GET_PRODUCT_AND_SERVICE_CATEGORIES';
export const GET_PRODUCTS_AND_SERVICES = 'GET_PRODUCTS_AND_SERVICES';
export const CHECKED_PRODUCTS_AND_SERVICES_LIST = 'CHECKED_PRODUCTS_AND_SERVICES_LIST';
export const GET_GLOBAL_PRODUCT_AND_SERVICE_NAMES = 'GET_GLOBAL_PRODUCT_AND_SERVICE_NAMES';
export const GET_PRODUCTS_AND_SERVICES_PRICES = 'GET_PRODUCTS_AND_SERVICES_PRICES';

export const ADD_UNITS_OF_MEASUREMENT = 'ADD_UNITS_OF_MEASUREMENT'
export const GET_UNITS_OF_MEASUREMENT = 'GET_UNITS_OF_MEASUREMENT';
export const CHECKED_UNITS_OF_MEASUREMENT_LIST = 'CHECKED_UNITS_OF_MEASUREMENT_LIST';

export const GET_LEDGER_ACCOUNTS = 'GET_LEDGER_ACCOUNTS';

export const ADD_WAREHOUSES = 'ADD_WAREHOUSES';
export const GET_WAREHOUSES = 'GET_WAREHOUSES';

export const ADD_TAX = 'ADD_TAX';
export const GET_TAX = 'GET_TAX';

export const ADD_PREFERENCES = 'ADD_PREFERENCES';
export const GET_PREFERENCES = 'GET_PREFERENCES';

export const ADD_EXPENSES = 'ADD_EXPENSES';
export const GET_EXPENSES = 'GET_EXPENSES';
//export const EDIT_EXPENSES = 'GET_EXPENSES';

export const ADD_PURCHASES = 'ADD_PURCHASES';
export const GET_PURCHASES  = 'GET_PURCHASES';


export const ADD_SALES = 'ADD_SALES';
export const GET_SALES  = 'GET_SALES';
export const GETTING_SALES_PAYMENTS  = 'GETTING_SALES_PAYMENTS';

export const ADD_SALES_ORDER  = 'ADD_SALES_ORDER';
export const GET_SALES_ORDER  = 'GET_SALES_ORDER';

export const ADD_SALES_RETURN = 'ADD_SALES_RETURN';
export const GET_SALES_RETURN = 'GET_SALES_RETURN';

export const ADD_PROFORMA_INVOICE = 'ADD_PROFORMA_INVOICE';
export const GET_PROFORMA_INVOICE = 'GET_PROFORMA_INVOICE';

export const ADD_JOURNAL_ENTRIES = 'ADD_JOURNAL_ENTRIES  ';
export const GET_JOURNAL_ENTRIES = 'GET_JOURNAL_ENTRIES  ';

export const GET_PRODUCTIONS = 'GET_PRODUCTIONS';

export const GET_CAPITAL_ACCOUNTS = 'GET_CAPITAL_ACCOUNTS';
export const ADD_CAPITAL_ACCOUNTS = 'ADD_CAPITAL_ACCOUNTS';

export const GET_DEBITS = 'GET_DEBITS';
export const ADD_DEBITS = 'ADD_DEBITS';
export const GET_DEBIT_NOTES = 'GET_DEBIT_NOTES';

export const ADD_PURCHASE_ORDERS = 'ADD_PURCHASE_ORDERS';
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
