import { create } from 'zustand';
import { persist } from 'zustand/middleware';

//Creating users store
let store = (set) => ({
  translate: false,

  toggleTranslator: () => {
    return set((state) => (
      {
        translate: !state.translate
      }
    ))
  },
})


//Create store
const useTranslateStore = create(persist(store, { name: 'language-translate' }));


export { useTranslateStore };