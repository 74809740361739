/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './LeftPaneContainer.scss';
import './../../../../utils/fontawesome/fontawesome-all.min.css';
import Module from './components/Module';
import ModuleContentHeader from './components/ModuleContentHeader';
import ModuleContentBody from './components/ModuleContentBody';







function LeftPaneContainer(props) {
	const { mobileMenuState, data} = props;
	const { handleModuleClick, handleSubModuleClose, handleSubModuleSelect, handleLogout} = props;

	const subModuleOpen = data.subModuleOpen
		? 'sidebar-module-content'
		: 'sidebar-module-content sidebar-submodule-close';

	const mobileMenuStyle = mobileMenuState ? { display: 'flex' } : {};

	return (
		<section className="sidebar-wrapper in-moble-view" style={mobileMenuStyle}>
			{/*--SIDE MODULES--*/}
			<div className="sidebar-module">
				<ul className="sidebar-module-nav">
					<Module 
						state={data} 
						handleModuleClick={handleModuleClick}  
						handleLogout={handleLogout} 
					/>
				</ul>

				{/* <Icon name="sign out" size="large" /> */}
			</div>
			{/*--SIDE MODULE CONTENT--*/}
			<div className={subModuleOpen}>
				{/*--SIDE MODULE CONTENT HEADER--*/}
				<div className="sidebar-module-content-header">
					<ModuleContentHeader
						state={data}
						handleSubModuleClose={handleSubModuleClose}
					/>
				</div>
				{/*--SIDE MODULE CONTENT BODY--*/}
				<div className="sidebar-module-content-body">
					<ModuleContentBody
						state={data}
						handleSubModuleSelect={handleSubModuleSelect}
					/>
				</div>
			</div>
		</section>
	);
}

export default LeftPaneContainer;
