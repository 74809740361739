/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, lazy, useEffect } from "react";
import { connect } from "react-redux";
//import { Loader, Placeholder, Segment } from "semantic-ui-react";
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { uiTabSelect, uiTabClose } from "../../../../redux/actions/uiTabsAction";
import Tabs from "./tabs/Tabs";
import ErrorBoundary from "../../../../modules/ErrorBoundary";
import * as Wrappers from "../../../../components/layout/Wrappers";
import { ModalLoader } from "../../../../components/ModalLoader";

const RightPaneContainer = (props) => {
  const { /* subModuleOpen,  */uiTabs, uiTabSelect, uiTabClose, history } = props;

  const handleTabSelect = e => {
    uiTabSelect(e.target.dataset);
    e.preventDefault();
  };

  const handleTabClose = (e, result) => {
    const { id } = result;
    uiTabClose(id);
    e.preventDefault();
  };

  /*------------- Dynamically loading modules -------------*/
  let activeModule = {
    title: "Default",
    moduleDirectoryName: "default",
    subModuleDirectoryName: "default",
    fileName: "Default"
  }; //Home default page

  if (uiTabs !== undefined && uiTabs.length > 0) {
    //Tab contents from redux
    activeModule = uiTabs.find(obj => {
      return obj.active === true;
    });
  }

  useEffect(() => {
    //Puting the active tab on the address bar
    history.replace(`/home/${activeModule.subModuleDirectoryName}/`);
  }, [activeModule.subModuleDirectoryName, history]);

  /* -------------------------------------------------------*/
  const DynamicModules = lazy(() =>
    import(
      `../../../../modules/${activeModule.moduleDirectoryName}/${activeModule.subModuleDirectoryName}/${activeModule.fileName}`
    )
  );



  /* -------------------------------------------------------*/
  /* const Loading = (
    <Loader style={{ top: "40vh" }} size="medium" active inline="centered" content="Loading..." />
  ); */
  /* -------------------------------------------------------*/
  return (
    <Suspense fallback={<ModalLoader inverted active={true}>Loading...</ModalLoader>}>
      <Wrappers.RightPaneContainer>{/* resize={subModuleOpen} */}
        <Tabs
          uiTabs={uiTabs}
          handleTabSelect={handleTabSelect}
          handleTabClose={handleTabClose}
        />
        <Wrappers.RightPaneContent>
          <ErrorBoundary>
            <DynamicModules history={history} />
          </ErrorBoundary>
        </Wrappers.RightPaneContent>
      </Wrappers.RightPaneContainer>
    </Suspense>
  );
  /* -------------------------------------------------------*/
};

const mapStateToProps = state => {
  return {
    uiTabs: state.uiTabsReducer.uiTabs
  };
};

const mapDispatchToProps = {
  uiTabSelect,
  uiTabClose
};
export default connect(mapStateToProps, mapDispatchToProps)(RightPaneContainer);
