import {
    GET_SALES,
    GETTING_SALES_PAYMENTS
} from '../actions/actionConstants';


const initialState = {
    sales: [],
    salesCount: 0,
    salesPayments: [],
    salesPaymentsCount: 0,
};

const salesReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_SALES:
            return {
                ...state,
                sales: action.payload.records,
                salesCount: action.payload.count,
            };

        case GETTING_SALES_PAYMENTS:
            return {
                ...state,
                salesPayments: action.payload.records,
                salesPaymentsCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default salesReducer;
