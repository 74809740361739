import axios from 'axios';
import { USER_LOGEDIN, USER_LOGOUT } from './actionConstants';
import { URL } from '../../utils/url'

export const logedIn = (loginData) => ({
	type: USER_LOGEDIN,
	loginData: loginData
});

export const getLanguage = (param) => (dispatch) => {
    return axios({
        method: 'get',
        url: `${URL}/api/v1/index.php/login`,
        headers: {
            'Content-type': 'application/json'
        },
        data: {}
    });
};

export const login = (credentials) => (dispatch) => {
	return axios({
		method: 'post',
		url: `${URL}/api/v1/index.php/login`,
		//timeout: 1000 * 5, // Wait for 5 seconds
		headers: {
			'Content-type': 'application/json'
		},
		data: {
			username: credentials.username,
			password: credentials.password
		}
	})
};

export const logedOut = () => ({
	type: USER_LOGOUT
});

export const logout = () => (dispatch) => {
	return axios({
		method: 'post',
		url: `${URL}/api/v1/index.php/logout`,
		headers: {
			'Content-type': 'application/json'
		},
		data: {}
	});
};
