import React from 'react';
import { connect } from "react-redux";
import { IntlProvider } from 'react-intl';
import {
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';
import messages from '../locale/messages';
import LoginContainer from '../pages/login/LoginContainer';
import HomeContainer from '../pages/home/HomeContainer';

import { getLanguage } from "../redux/actions/logInOutAction";

//import { isEmpty_util } from "../utils/utils";
import { SUCCESS } from "../utils/genericConstants";







function App(props) {
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	const [language, setLanguage] = React.useState('en')//Default system language is english
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<FETCHING SYSTEM SETTINGS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	React.useEffect(() => {
		props.getLanguage().then((response) => {
			if (response.status === SUCCESS) {
				setLanguage(response.data.language);
			}
		}).catch(error => {
			console.log(error);
		});
	}, [])
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


	return (
		<IntlProvider locale={language} messages={messages[language]} textComponent="span">
			<Switch>
				<Route exact path="/" component={LoginContainer} />
				<Route path="/home" component={HomeContainer} />
				<Route path="*">
					<Redirect to="/home" />
				</Route>
			</Switch>
		</IntlProvider>
	);
}


const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
	getLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)