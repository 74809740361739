import React from 'react'
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import sha512 from 'sha512';

import {
    snackbarOpen,
    reauthenticateToggle,
} from "../../../../../redux/actions/uiAction";

import {
    changePassword,
} from "../../../../../redux/actions/userAction";

import { 
    SUCCESS, 
    BAD_REQUEST, 
    UNAUTHORISED, 
    FORBIDDEN, 
    INVALID_CREDENTIALS,
    EMPTY_PLACEHOLDER,
 } from "../../../../../utils/genericConstants";

import {
    Button,
    Form,
    Icon,
    Modal,
    Grid,
    Label,
} from 'semantic-ui-react'
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Select } from "../../../../../components/Select";
import { Input } from "../../../../../components/Input";
import { ErrorLabel } from '../../../../../components/ErrorLabel';
import { FormLabel } from "../../../../../components/FormLabel";

import Img_upload_placeholder from '../../../../../images/user_avatar.png';

import { API_PATH } from "../../../../../utils/url.js";
import { isEmpty_util } from "../../../../../utils/utils.js";


const statusOptions = [
    { key: "1", text: "Active", value: "Active" },
    { key: "2", text: "Inactive", value: "Inactive" },
];

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const initialValues = {
    password: '',
    confirmPassword: '',
}
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
let validationSchema = Yup.object().shape({
    password: Yup.string().required(),

    confirmPassword: Yup.string().test('password-match', 'Passwords do not match', function (value) {
        const { password } = this.parent;
        return password === value;
    }),
});
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<




function UserPasswordChange(props) {
    const [userDetails, setUserDetails] = React.useState({
        userId: '',
        methodType: '',
        profileImage: '',
        fullName: '',
        username: '',
        email: '',
        mobile: '',
        outletName: '',
        roleName: '',
        status: 'Active',
    })

    //<<<<<<<<<<<<<<<<<< USING FORMIK <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,

        onSubmit: async (values) => {
            try {
                //Hashing the password
                const password = sha512(values[ 'password' ]).toString('hex');

                //<<<<<<<<<<<<<<< SUBMIT NEW >>>>>>>>>>>>>>>>>>>>
                const response = await props.changePassword({ password });

                console.log(response);

                if (response.status === SUCCESS) {
                    props.callback(response);

                    props.snackbarOpen({
                        sbState: true,
                        sbType: "success",
                        sbMessage: "Password successively changed.",
                    });

                    //Resetting form.
                    formik.resetForm(initialValues);
                }

                formik.setSubmitting(false);

            } catch (error) {
                formik.setSubmitting(false);
                //Handling error
                handleError(error)
            };
        },
    });
    //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    //<<<<<<<<<<<<<<<<<<<<<<<<CREATING OUTLET OPTIONS<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    React.useEffect(() => {
        const userData = props.userData;

        const userDetails = {
            fullName: userData[ 'fullName' ],
            username: userData[ 'username' ],
            outletName: userData[ 'outletName' ],
            roleName: userData[ 'roleName' ],
            mobile: userData[ 'mobile' ],
            email: userData[ 'email' ],
            status: userData[ 'status' ],
    
        }

        if (!isEmpty_util(userData[ 'profileImage' ])) {
            userDetails['profileImage'] = API_PATH + userData[ 'profileImage' ];
        } else {
            userDetails['profileImage'] = Img_upload_placeholder;
        }
       
    
        setUserDetails(userDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ props.userData ]);
    //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    const handleChange = (event, result) => {
        const { name, value } = (result === undefined) ? event.target : result;

        formik.setFieldValue(name, value, true);

    }
    //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>ERROR HANDLING>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const handleError = (error) => {
        //Check if error.reponse exist.
		if(isEmpty_util(error.response)){
			console.log(
			  `Password Change Report Error: ${error.message}`
			);
			//Exit from func.
			return;
		}
        if (error.response.status === UNAUTHORISED) {
            //Unauthorised: User is not authenticated. Expired cookie redirect to login  Code: 401
            props.history.replace(`/`);
        } else if (error.response.status === FORBIDDEN) {
            //Forbidden: Token expired reauthenticate  Code: 403
            props.reauthenticateToggle();
        } else if (error.response.status === INVALID_CREDENTIALS) {
            //Account is locked for a while. Code: 422
            props.reauthenticateToggle();
        } else if (error.response.status === BAD_REQUEST) {
            //Password is invalid. Code: 400
            props.reauthenticateToggle();
        } else {
            props.snackbarOpen({
                sbState: true,
                sbType: "error",
                sbMessage: error.response.statusText
            });
        }
        console.log(`Password Change  Error: ${error.response.status} - ${error.response.statusText}`);
    };
    //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>




    return (
        <Modal
            open={props.modal.open}
            onOpen={props.modalOpen}
            onClose={props.modalClose}
            closeOnEscape={true}
            closeOnDimmerClick={false}
            size="small"
        >
            <Modal.Header>
                <Grid>
                    <Grid.Column textAlign="left" width={8}>
                        Change Password
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={8}>
                        <Button
                            basic
                            size="small"
                            icon='close'
                            onMouseUp={props.modalClose}
                            autoFocus
                        />
                    </Grid.Column>
                </Grid>
            </Modal.Header>
            <Modal.Content >



                <Form size="small" loading={formik.isSubmitting}>
                    <Grid stackable celled="internally">
                        <Grid.Column width={6}>
                            <Form.Field>
                                <FormLabel>Profile Image</FormLabel>

                                <div
                                    style={{
                                        padding: '8px',
                                        backgroundColor: 'transparent',
                                        border: '2px dashed #CCCCCC',
                                        borderRadius: '5px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        src={userDetails.profileImage}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                        }}
                                    />
                                </div>

                            </Form.Field>

                            <Form.Field>
                                <FormLabel htmlFor="status">Status</FormLabel>
                                <Label basic style={{ width: '100%', border: 'none' }}>
                                    {userDetails.status}
                                </Label>
                            </Form.Field>

                        </Grid.Column>
                        <Grid.Column width={10}>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormLabel htmlFor="fullName">Full Name *</FormLabel>
                                    <Label basic style={{ width: '100%', border: 'none' }}>
                                        {userDetails.fullName}
                                    </Label>
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormLabel htmlFor="username">Username *</FormLabel>
                                    <Label basic style={{ width: '100%', border: 'none' }}>
                                        {userDetails.username}
                                    </Label>
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormLabel htmlFor="email">Email Address</FormLabel>
                                    <Label basic style={{ width: '100%', border: 'none' }}>
                                        {(isEmpty_util(userDetails.email)) ? EMPTY_PLACEHOLDER : userDetails.email}
                                    </Label>
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormLabel htmlFor="mobile">Mobile Numbers</FormLabel>
                                    <Label basic style={{ width: '100%', border: 'none' }}>
                                        {(isEmpty_util(userDetails.mobile)) ? EMPTY_PLACEHOLDER : userDetails.mobile}
                                    </Label>
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field width='4'>
                                    <FormLabel htmlFor="outletName">Outlet *</FormLabel>
                                    <Label basic style={{ width: '100%', border: 'none' }}>
                                        {userDetails.outletName}
                                    </Label>
                                </Form.Field>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Form.Field width='4'>
                                    <FormLabel htmlFor="roleName">System Role *</FormLabel>
                                    <Label basic style={{ width: '100%', border: 'none' }}>
                                        {userDetails.roleName}
                                    </Label>
                                </Form.Field>
                            </Form.Group>





                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormLabel htmlFor="password">Password *</FormLabel>
                                    <Input
                                        id="password"
                                        name="password"
                                        type="password"
                                        icon="lock"
                                        iconPosition="left"
                                        autoComplete="off"
                                        value={formik.values.password}
                                        onChange={handleChange}
                                        error={formik.errors.password && formik.touched.password ? true : false}
                                    />
                                    {(formik.errors.password && formik.touched.password) ?
                                        <ErrorLabel
                                            size="tiny"
                                            color="black"
                                            pointing
                                            display={(formik.errors.password && formik.touched.password) ? 'true' : 'false'}
                                        >
                                            <FormattedMessage id={'more.than.6.characters'} defaultMessage="This is a required field and should be more than 6 characters" />
                                        </ErrorLabel>
                                        : null
                                    }
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <FormLabel htmlFor="confirmPassword">Confirm Password *</FormLabel>
                                    <Input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        icon="lock"
                                        iconPosition="left"
                                        autoComplete="off"
                                        value={formik.values.confirmPassword}
                                        onChange={handleChange}
                                        error={formik.errors.confirmPassword && formik.touched.confirmPassword ? true : false}
                                    />
                                    {(formik.errors.confirmPassword && formik.touched.confirmPassword) ?
                                        <ErrorLabel
                                            size="tiny"
                                            color="black"
                                            pointing
                                            display={(formik.errors.confirmPassword && formik.touched.confirmPassword) ? 'true' : 'false'}
                                        >
                                            <FormattedMessage id={'passwords.do.not.match'} defaultMessage="Passwords do not match." />
                                        </ErrorLabel>
                                        : null
                                    }
                                </Form.Field>
                            </Form.Group>

                        </Grid.Column>
                    </Grid>
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button primary onClick={formik.submitForm}>
                    Update Password <Icon name='chevron right' />
                </Button>
            </Modal.Actions>
        </Modal>
    )

}
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<



//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
UserPasswordChange.propTypes = {
    /*  type: PropTypes.shape({
         name: PropTypes.string,
         data: PropTypes.object,
     }).isRequired, */
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const mapStateToProps = state => {
    return {
        outlets: state.outletReducer.outlet,
        roles: state.rolesAndAccessReducer.roles,
    };
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const mapDispatchToProps = {
    snackbarOpen,
    reauthenticateToggle,
    changePassword ,
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
export default connect(mapStateToProps, mapDispatchToProps)(UserPasswordChange);