import React from 'react';
import { connect } from 'react-redux';
import { snackbarOpen, reauthenticateError } from '../../../redux/actions/uiAction';
import HeaderLeft from './left/HeaderLeft';
import HeaderRight from './right/HeaderRight';
//import GlobalSearch from './middle/GlobalSearch';
import * as Wrappers from "../../../components/layout/Wrappers";
//import useLocalStorageState from 'use-local-storage-state';

import { useTranslateStore } from "../../../store/useTranslateStore";









function HeaderContainer(props) {
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<< SEARCH FILTER INPUT VALUES <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	const { userData, snackbarOpen } = props;

	const [ state, setState ] = React.useState({
		userData: {}
	});

	const toggleTranslator = useTranslateStore((state) => state.toggleTranslator);
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

	React.useEffect(() => {
		setState({ userData: userData });
	}, [ userData ]);

	const handleTranslate = () => {
		//Handle chinese translation
		toggleTranslator();
	};



	return (
		<Wrappers.HeaderContainer className="header-wrapper">
			<HeaderLeft
				handleMobileMenu={props.handleMobileMenu}
			/>
			{/* <GlobalSearch /> */}
			<HeaderRight
				handleLogout={props.handleLogout}
				handleTranslate={handleTranslate}
				userData={state.userData}
			/>
		</Wrappers.HeaderContainer>
	);
}

/* Connecting to redux */
const mapStateToProps = (state) => {
	return {
		userData: state.uiReducer.userData
	};
};

const mapDispatchToProps = {
	snackbarOpen,
	reauthenticateError
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
