/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export default function Module(props) {
	const { state, handleSubModuleSelect } = props;

	if (state.modules.length > 0) {
		return (
			<ul>
				{state.modules[ state.activeModule ]?.subModule.map((value, index) => (


					<li className="fas fa-list-alt" key={index}>
						<a
							key={index}
							// eslint-disable-next-line no-script-url
							href="#"
							onClick={handleSubModuleSelect}
							data-title={value.title}
							data-module_directory_name={state.modules[ state.activeModule ].moduleDirectoryName}
							data-sub_module_directory_name={value.subModuleDirectoryName}
							data-file_name={value.fileName}
						>
							{value.title}
						</a>
					</li>


				))}
			</ul>
		);
	} else {
		return null;
	}
}
