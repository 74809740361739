/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Popup } from 'semantic-ui-react';


export default function Module(props) {
	const { state, handleModuleClick, handleLogout } = props;

	let modules = null;
	//Will show tooltip on modules
	if (typeof state.modules != 'undefined') {
		if (state.modules.length > 0) {

			modules = state.modules.map((value, index) => (
				<Popup
					disabled={!state.showTooltip}
					key={index}

					trigger={
						<li key={index}>
							<a
								href="#"
								data-id={index}
								onClick={handleModuleClick}
								className={value.active ? 'sidebar-module-active' : 'sidebar-module-not-active'}
							>
								<span
									style={{ pointerEvents: 'none' }}
									className={value.icon + (value.active ? ' fa-spin' : '')}
								/>
							</a>
						</li>
					}

					content={value.title}
					position="right center"
					style={{ borderRadius: 5, padding: '4px 8px 4px 8px' }}
					size="mini"
				/>
			));


			modules.push(
				<Popup
					disabled={!state.showTooltip}
					key={'sign-out'}
					trigger={
						<li style={{
							position: 'fixed', bottom: 0, width: 'inherit',
						}}>
							<a
								href="#"
								onClick={handleLogout}
								className={'sidebar-module-not-active'}
								style={{ color: '#ffcbd1', fontSize: '20px' }}
							>
								<span
									style={{ pointerEvents: 'none' }}
									className='fa fa-sign-out-alt'
								/>
							</a>
						</li>
					}

					content="Log Out"
					position="right center"
					style={{ borderRadius: 5, padding: '4px 8px 4px 8px' }}
					size="mini"
				/>
			)
		}
	}

	return modules;
}
