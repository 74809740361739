import React from 'react';
import { Select as Select_ } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledInput = styled(Select_)`  
   
    border-radius: 4px !important;
    border-width: 1px !important; 
    border-style: solid !important; 
    border-color: ${(props) => (props.error ? '#db2828' : '#ced4da')} !important;
   
  
    .menu {
        border: 1px solid ${(props) => (props.error ? '#db2828' : '#263549')} !important;
       /*  color: #113948 !important; */
        .text{
            font-size: .9em;
        }
    }

    :hover {
        box-shadow: 0 0 0 2px rgba(161, 161, 161, 0.25) !important;
    }

    :focus {
      border: 1px solid ${(props) => (props.error ? 'none' : '#263549')} !important;
	  outline: none !important;
  }
`;

export function Select(props) {
    return <StyledInput {...props} />;
}
