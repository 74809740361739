import React, { Suspense } from 'react'
import styled from "styled-components";
import PropTypes from "prop-types";
import { ModalLoader } from "../ModalLoader";



//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
/*CONTAINER */
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
const StyledOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
   /*  padding-top: 50px; */
    width: 100%;
    height: 100%;
    z-index: 1005;
    
    ${props => props.open ? "display: block" : "display: none"};
`;

export function PrintingContainer(props) {
    const [ModalComponents, setModalComponents] = React.useState(null);

    /* -------------------------------------------------------*/
    React.useEffect(() => {
        if (props.componentPath !== null) {
            const ModalComponent = React.lazy(() => import(`../../modules/${props.componentPath}`));
            setModalComponents(<ModalComponent data={props.data}/>);
        } else {
            setModalComponents(null);
        }
    }, [props.componentPath, props.data]);
    /* -------------------------------------------------------*/

    /* -------------------------------------------------------*/
    const Loading = (
        <ModalLoader active={true} style={{ top: "40vh" }} inverted={true}>Loading...</ModalLoader>
    );

    return (
        <StyledOverlay open={props.isOpen} className={props.className} name="printingContainer">
            <Suspense fallback={Loading}>
                {ModalComponents}
            </Suspense>
        </StyledOverlay>
    )
}

PrintingContainer.propTypes = {
    componentPath: PropTypes.string,
    isOpen: PropTypes.bool,
};






