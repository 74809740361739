
const initialState = {
    
    initOptions: [
        { key: 0, value: "all", text: "All" }
    ],

    itemTypeOptions: [
        { key: 1, value: 'finishedItem', text: 'Finished Item', label: { 
            color: 'green', 
            empty: true, 
            circular: true 
        } },
        { key: 2, value: 'semiFinishedItem', text: 'Semi - Finished Item', label: { 
            color: 'blue', 
            empty: true, 
            circular: true 
        } },
        { key: 3, value: 'rawMaterial', text: 'Raw Material', label: { 
            color: 'red', 
            empty: true, 
            circular: true 
        } },
        { key: 4, value: 'fixedAsset', text: 'Fixed Asset', label: { 
            color: 'purple', 
            empty: true, 
            circular: true 
        } },
        { key: 5, value: 'consumableItem', text: 'Consumable Item', label: { 
            color: 'purple', 
            empty: true, 
            circular: true 
        } },
        { key: 6, value: 'serviceItem', text: 'Service Item', label: { 
            color: 'purple', 
            empty: true, 
            circular: true 
        } },
    ],

    prodServSortByOptions: [
        { key: 1, text: "Products /Services Name", value: "globalItemName" },
       /*  { key: 2, text: "Category", value: "itemCategoryId" }, */
    ],


    latePaymentOptions: [
        { key: 1, text: "Do not charge.", value: "dont" },
        { key: 2, text: "Monthly percentage charge ", value: "percentage" },
        { key: 3, text: "Monthly value charge", value: "fixedValue" },
    ],


    inventoryListDiscountRuleOptions: [
        { key: 1, text: "Percentage Off", value: "percentage_Off" },
        { key: 2, text: "Amount Off", value: "amount_Off" },
        { key: 3, text: "Buy X, Get Y percent off", value: "buy_X_Get_Y_percent_Off" },
        { key: 4, text: "Buy X, Get Y at Z percent off", value: "buy_X_Get_Y_at_Z_percent_off" },
        { key: 5, text: "Discount Triggered by Sales Quantity Total", value: "discount_triggered_by_sales_quantity_total" },
    ],

    customerListDiscountRuleOptions: [
        { key: 1, text: "Basic - Percent Off", value: "Percentage" },
        { key: 2, text: "Basic - Amount Off", value: "Value" },
        { key: 3, text: "Basic - Fixed Price", value: "Value" },
        { key: 4, text: "Discount Triggered by Sales Total", value: "Value" },
    ],

    completeSaleDiscountRuleOptions: [
        { key: 1, text: "Complete Sale - Percent Off (Coupon Code)", value: "Percentage" },
        { key: 2, text: "Complete Sale - Amount Off (Coupon Code)", value: "Value" },
    ],

    statusOptions: [
        { key: 1, text: "Active", value: "Active" },
        { key: 2, text: "Inactive", value: "Inactive" },
    ],

    accountTypeOptions: [
        { key: 0, text: "All", value: "All" },
        { key: 1, text: "Asset", value: "Asset" },
        { key: 2, text: "Liability", value: "Liability" },
        { key: 3, text: "Equity", value: "Equity" },
        { key: 4, text: "Income", value: "Income" },
        { key: 5, text: "Expenses", value: "Expenses" },
    ],

    warehousesSortByOptions: [
        { key: 1, text: "Name", value: "name" },
        { key: 2, text: "Contact Name", value: "contactName" },
    ],

    taxSortByOptions: [
        { key: 1, text: "Tax Name", value: "taxName" },
        { key: 2, text: "Tax Type", value: "taxType" },
    ],

    percentageValueOptions: [
        { key: 1, text: "Percentage", value: "percentage" },
        { key: 2, text: "Fixed Value", value: "fixedValue" },
    ],

    taxTypeOptions: [
        { key: 1, text: "Simple Tax", value: "simpleTax" },
        { key: 2, text: "Compound Tax", value: "compoundTax" },
    ],

    priceOption: [
        { key: 0, text: "All", value: "all" },
        { key: 1, text: "Retail", value: "retail" },
        { key: 2, text: "Wholesale", value: "wholesale" },
        { key: 3, text: "Other", value: "other" },
    ],
}

initialState['currencyOptions'] = [
   
];

initialState['dateFormatOptions'] = [
  /*   { key: 1, text: "dd/MM/yyyy", value: "dd/MM/yyyy" }, */
    { key: 2, text: "dd-MM-yyyy", value: "dd-MM-yyyy" },
    { key: 3, text: "dd-MMM-yyyy", value: "dd-MMM-yyyy" },
   /*  { key: 4, text: "dd/MMM/yyyy", value: "dd/MMM/yyyy" }, */
   /*  { key: 5, text: "dd.MM.yyyy", value: "dd.MM.yyyy" }, */
   /*  { key: 6, text: "yyyy/MM/dd", value: "yyyy/MM/dd" }, */
    { key: 7, text: "yyyy-MM-dd", value: "yyyy-MM-dd" },
   /*  { key: 8, text: "yyyy.MM.dd", value: "yyyy.MM.dd" },
    { key: 9, text: "MMM d, yyyy", value: "MMM d, yyyy" }, */
];

initialState['moneyFormatOptions'] = [
    { key: 1, text: "0,0.00", value: "0,0.00", decimal: 2 },
    { key: 2, text: "0,0[.]00", value: "0,0[.]00", decimal: 2 },
    { key: 3, text: "0,0.0000", value: "0,0.0000", decimal: 4 },
    { key: 4, text: "0,0[.]0000", value: "0,0[.]0000", decimal: 4 },
];

initialState['numberFormatOptions'] = [
    { key: 1, text: "0,0.[0000]", value: "0,0.[0000]", decimal: 4 },
    { key: 2, text: "0,0.00", value: "0,0.00", decimal: 2 },
];

initialState['languageOptions'] = [
    { key: 1, text: "English", value: "en" },
];

initialState['expenseSortByOptions'] = [
    { key: 1, text: "Date", value: "date" },
    { key: 2, text: "Payee", value: "payee" },
    { key: 3, text: "System No", value: "refID" },
    { key: 4, text: "Reference Number", value: "referenceNumber" },
    { key: 5, text: "Payment Account", value: "paymentAccountName" },
];

initialState['salesTransactionSortByOptions'] = [
    { key: 1, text: "Date", value: "date" },
    { key: 2, text: "Type", value: "type" },
    { key: 3, text: "System No", value: "refID" },
    { key: 4, text: "Reference Number", value: "referenceNumber" },
    { key: 5, text: "Customer Name", value: "customerName" },
];

initialState['capitalRecordTypeOptions'] = [
    { key: 1, text: "Capital Accounts", value: "capital_accounts" },
    { key: 2, text: "Capital Transaction", value: "capital_transactions" },
];

initialState['supplierReportTypeOptions'] = [
	{ key: 1, text: "Raw Material Purchases", value: "purchases" },
    { key: 2, text: "Bills", value: "bills" },
];

initialState['expenseRecordTypeOptions'] = [
    { key: 1, text: "Expenses/ Bills", value: "expenses_and_bills" },
    { key: 2, text: "Raw Material Purchases", value: "purchase_raw_material" },
    { key: 3, text: "Bill/ Purchase Payments", value: "bill_payments" },
    { key: 4, text: "Debit Note", value: "debit_note" },
    { key: 5, text: "Purchase Order", value: "purchase_order" },
   /*  { key: 5, text: "Expense Claim", value: "expense_claim" }, */
];

initialState['purchaseTypeOptions'] = [
    { key: 1, text: "Bill", value: "bill" },
    { key: 2, text: "Raw Material Purchase", value: "raw_material_purchase" },
];

initialState['expenseTransactionTypeOptions'] = [
    { key: 1, text: "Expenses", value: "expenses" },
    { key: 2, text: "Bills", value: "bills" },
    
];

initialState['purchaseOrSalesTypeOptions'] = [
    { key: 1, text: "Local", value: "Local" },
    { key: 2, text: "Import", value: "Import" },
];


initialState['purchaseSortByOptions'] = [
    { key: 1, text: "Date", value: "date" },
    { key: 2, text: "Supplier", value: "supplier" },
];

initialState['salesTransactionTypeOptions'] = [
    { key: 1, text: "All Sales", value: "all_sales" },
    { key: 3, text: "Sales Invoice Payment", value: "sales_invoice_payments" },
    { key: 4, text: "Proforma Invoice", value: "proforma_invoice" },
    { key: 5, text: "Sales Return", value: "sales_return" },
    { key: 6, text: "Sales Order", value: "sales_order" },
]

initialState['salesTypeOptions'] = [
    { key: 1, text: "Sales Invoice", value: "sales_invoice" },
    { key: 2, text: "Sales Receipt", value: "sales_receipt" },
];

initialState['journalEntriesTypeOptions'] = [
    { key: 1, text: "Journal Entries", value: "journal_entries" },
];

initialState['journalEntriesSortByOptions'] = [
    { key: 1, text: "Date", value: "date" },
];

initialState['productionTypeOptions'] = [
    { key: 1, text: "Production", value: "production" },
];

initialState['productionSortByOptions'] = [
    { key: 1, text: "Date", value: "date" },
    { key: 2, text: "Finished item", value: "finishedItem" },
    { key: 3, text: "Batch Nnmber", value: "batchNnmber" },
];

initialState['paymentMethodOptions'] = [
    { key: 1, text: "Cash", value: "cash" },
    { key: 2, text: "Mobile Money Payment", value: "mobile_money_payment" },
    { key: 3, text: "Bank Transfer", value: "bank_transfer" },
    { key: 4, text: "Cheque", value: "cheque" },
];

initialState['paymentMethodStateOptions'] = [
    { key: 1, text: "Cleared", value: "cleared" },
    { key: 2, text: "Pending", value: "pending" },
];

initialState['salesReturnTypeOptions'] = [
    { key: 1, text: "Credit Note", value: "credit_note" },
    { key: 2, text: "Sales Refund", value: "sales_refund" },
];

initialState['customerTypeOptions'] = [
    { key: 1, text: "Registered", value: "registered" },
    { key: 2, text: "Unregistered", value: "unregistered" },
];

initialState['startingBalanceTypeOptions'] = [
    { key: 1, text: "Paid in advance", value: "paid_in_advance" },
    { key: 2, text: "Amount to pay", value: "amount_to_pay" },
];

initialState['adjustmentTypeOptions'] = [
    { key: 1, text: "Quantity", value: "quantity" },
];

initialState['colorOptions'] = [
    'red',
    'blue',
    'purple',
    'black',
    'orange',
    'yellow',
    'pink',
    'green',
    'olive',
    'teal',
    'violet',
    'brown',
    'grey'
];




const enumReducer = (state = initialState, action = {}) => {
    return state;
};

export default enumReducer;