import { GET_OUTLET } from '../actions/actionConstants';

const initialState = {
    outlet: []
};
const outletReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_OUTLET:
            return {
                ...state,
                outlet: action.payload,
            };
        default:
            return state;
    }
};

export default outletReducer;
