import { combineReducers } from 'redux';
import enumReducer from './enumReducer';
import logInOutReducer from './logInOutReducer';
import uiReducer from './uiReducer';
import uiTabsReducer from './uiTabsReducer';
import userReducer from './userReducer';
import errorReducer from './errorReducer';
import globalModalReducer from './globalModalReducer';
import customersReducer from './customersReducer';
import suppliersReducer from './suppliersReducer';
import outletReducer from './outletReducer';
import rolesAndAccessReducer from './rolesAndAccessReducer';
import productAndServiceReducer from './productAndServiceReducer';
import unitsOfMeasurementReducer from './unitsOfMeasurementReducer';
import accountsReducer from './accountsReducer';
import warehousesReducer from './warehousesReducer';
import taxesReducer from './taxesReducer';
import preferencesReducer from './preferencesReducer';
import expensesReducer from './expensesReducer';
import purchasesReducer from './purchasesReducer';
import salesReducer from './salesReducer';
import journalEntriesReducer from './journalEntriesReducer';
import productionsReducer from './productionsReducer';
import capitalReducer from './capitalReducer';
import salesOrderReducer from './salesOrderReducer';
import salesReturnReducer from './salesReturnReducer';
import proformaInvoiceReducer from './proformaInvoiceReducer';
import debitsReducer from './debitsReducer';
import purchaseOrderReducer from './purchaseOrderReducer';

const rootReducer = combineReducers({
	enumReducer,
	logInOutReducer,
	uiReducer,
	uiTabsReducer,
	userReducer,
	errorReducer,
	globalModalReducer,
	customersReducer,
	suppliersReducer,
	outletReducer,
	rolesAndAccessReducer,
	productAndServiceReducer,
	unitsOfMeasurementReducer,
	accountsReducer,
	warehousesReducer,
	taxesReducer,
	preferencesReducer,
	expensesReducer,
	purchasesReducer,
	salesReducer,
	journalEntriesReducer,
	productionsReducer,
	capitalReducer,
	salesOrderReducer,
	salesReturnReducer,
	proformaInvoiceReducer,
	debitsReducer,
	purchaseOrderReducer,
});

export default rootReducer;
