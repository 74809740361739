import { SELECT_SUBMODULE, SELECT_UI_TAB, CLOSE_UI_TAB } from './actionConstants';

export const subModuleSelected = (dataset) => ({
	type: SELECT_SUBMODULE,
	payload: dataset
});

export const uiTabSelected = (dataset) => ({
	type: SELECT_UI_TAB,
	payload: dataset
});

export const uiTabClosed = (index) => ({
	type: CLOSE_UI_TAB,
	payload: index
});


export const selectSubModule = (dataset) => (dispatch) => {
	/*Dispatch action */
	dispatch(subModuleSelected(dataset));
};

export const uiTabSelect = (dataset) => (dispatch) => {
	/*Dispatch action */
	dispatch(uiTabSelected(dataset));
};

export const uiTabClose = (id) => (dispatch) => {


	/*Dispatch action */
	dispatch(uiTabClosed(id));
};


