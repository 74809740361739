export const SUCCESS = 200;
export const BAD_REQUEST = 400;

export const UNAUTHORISED = 401;
export const ACCESS_DENIED = 423;

export const FORBIDDEN = 403;
export const INVALID_CREDENTIALS = 422;
export const MOBILE_SIZE = 768;

export const Cell_CONTENT_HEIGHT = '33px';
export const EMPTY_PLACEHOLDER = '........... ........... ........... ...........';

export const DATABASE_LIMIT = 100;
export const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';