import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Icon, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input } from './Input';
import { ErrorLabel } from './ErrorLabel';


const validationSchema = Yup.object().shape({
	password: Yup.string().min(4, 'login.password.tooShort').required('login.password.empty')
});


const initialValues = {
	username: '',
	password: '**********'
}


const Reauthenticate = React.memo((props) => {
	const { isOpen, isLoading, error, errorType, submit, username } = props;

	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< USING FORMIK <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: values => {
			submit(values);
			formik.resetForm(initialValues);
		},
	});
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	React.useEffect(() => {
		if (username !== undefined) {
			formik.setFieldValue('username', username, false);
		}
	}, [username])
	//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<



	return (
		<Modal size="mini" dimmer="blurring" open={isOpen} closeOnDimmerClick={false}>
			<Modal.Header>
				<FormattedMessage id="home.reauthentication" defaultMessage="Reauthentication" />
			</Modal.Header>
			<Modal.Content>
				<Message
					error
					size="mini"
					style={{ display: error ? 'block' : 'none' }}
				>
					<Message.Header>
						<FormattedMessage id="error" defaultMessage="Error" />:
							</Message.Header>
					<p>
						{errorType === false ? (
							<FormattedMessage
								id="login.password.incorrect"
								defaultMessage="Password is incorrect."
							/>
						) : (
								<FormattedMessage
									id="login.user.locked"
									defaultMessage="Account is locked for a while."
								/>
							)}
					</p>
				</Message>

				<p>
					<FormattedMessage
						id="home.reauthentication.message"
						defaultMessage="Your session has expired, enter password to get in."
					/>
				</p>
				<Form loading={isLoading}>
					<Form.Field widths="equal">
						<FormattedMessage id="login.enter.password" defaultMessage="Enter password...">
							{(placeholder) => (
								<Input
									name="password"
									type="password"
									icon="lock"
									iconPosition="left"
									size="small"
									fluid
									autoComplete="off"
									value={formik.values.password}
									placeholder={placeholder}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									errcolor={formik.errors.password && formik.touched.password ? 'true' : 'false'}
									error={formik.errors.password && formik.touched.password ? true : false}
								/>
							)}
						</FormattedMessage>
						<ErrorLabel
							size="small"
							color="red"
							pointing
							display={formik.errors.password && formik.touched.password ? 'true' : 'false'}
						>
							<Icon name="warning" />
							<FormattedMessage id={formik.errors.password ? formik.errors.password : 'empty'} />
						</ErrorLabel>
					</Form.Field>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button type="submit" secondary onClick={formik.submitForm} disabled={isLoading}>
					<FormattedMessage id="home.reauthenticate" defaultMessage="Authenticate" />
				</Button>
			</Modal.Actions>
		</Modal>

	);
})

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
Reauthenticate.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	errorType: PropTypes.bool.isRequired,
	submit: PropTypes.func.isRequired,
	username: PropTypes.string,
};
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


export default Reauthenticate;