import React from 'react'
import PropTypes from "prop-types";
import { Message, Icon } from 'semantic-ui-react'



export function ConnectionStatus(props) {
    const [state, setState] = React.useState(false);

    React.useEffect(() => {
        setState(props.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state]);


    const handleDismiss = () => {
        setState(false)
        /*  setTimeout(() => {
             setState({ visible: true })
         }, 2000) */
    }

    return (
        (state) ?
            <Message color='red' attached='bottom' style={{ 
                    zIndex: 100000, 
                    margin: 0, 
                    borderRadius: 0,
                    width: '100%',
                    height: '50px',
                    position: 'absolute',
                    bottom: 0,
                }} 
                onDismiss={handleDismiss}
            >
                <Icon name='wifi' />
                You're offline. Try the following: Check your modem and router or reconnect to Wi-Fi.
            </Message>
            :
            null
    )
}

ConnectionStatus.propTypes = {
    state: PropTypes.bool.isRequired,
  };