import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
/* import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'; */
import MuiAlert from '@material-ui/lab/Alert';




const useStyles = makeStyles((theme) => ({
	close: {
		padding: theme.spacing(0.5)
	},
	error: {
		backgroundColor: '#eeeeee'
	},
	warning: {
		backgroundColor: '#ffa000'
	},
	info: {
		backgroundColor: '#90caf9'
	},
	success: {
		backgroundColor: '#278371'
	},
	default: {}
}));


function SnackbarList(props) {

	return (
		<ol>
			{
				props.data.map((msg, index) => {
					return (<li key={index} style={{ listStyle: 'inside' }}>
						{msg}
					</li>);
				})
			}
		</ol>
	)
}


export default function SimpleSnackbar(props) {
	const { sbState, sbType, sbMessage, sbClose } = props;
	const classes = useStyles();


	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			open={sbState}
			autoHideDuration={6000}
			onClose={sbClose}
			ContentProps={{
				'aria-describedby': 'message-id',
				classes: {
					root: classes[ sbType ]
				}
			}}
		>
			<MuiAlert elevation={6} variant="filled" onClose={sbClose} severity={sbType}>
				{
					(typeof sbMessage === 'object') ?
						<SnackbarList data={sbMessage} />
						:
						sbMessage
				}
			</MuiAlert>
		</Snackbar>

	);
}



