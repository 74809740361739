import {
    GET_PURCHASES,
} from '../actions/actionConstants';

const initialState = {
    purchases: [],
    puchasesCount: 0,
};
const purchasesReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_PURCHASES:
            return {
                ...state,
                purchases: action.payload.records,
                puchasesCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default purchasesReducer;
