import {
    GET_SALES_RETURN,
} from '../actions/actionConstants';


const initialState = {
    salesReturn: [],
    salesReturnCount: 0,
};

const salesReturnReducer = (state = initialState, action = {}) => {
    switch (action.type) {

        case GET_SALES_RETURN:
            
            return {
                ...state,
                salesReturn: action.payload.records,
                salesReturnCount: action.payload.count,
            };

        default:
            return state;
    }
};

export default salesReturnReducer;
