import React from 'react';
import { Input as Input_ } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledInput = styled(Input_)`  
   &&{
    :hover {
        box-shadow: 0 0 0 2px rgba(161, 161, 161, 0.25) !important;
        border-radius: 5px !important;
    }

    
    input {
        border: 1px solid ${(props) => (props.error ? '#db2828' : '#ced4da')} !important;
        border-right-color: ${(props) => (props.error ? '#db2828' : '#ced4da')} !important;
        text-align: ${(props) => (props.textalign === 'right' ? 'right' : 'left')}; 
        
        :focus {
            border: 1px solid ${(props) => (props.error ? 'none' : '#263549')} !important;
            border-right-color: ${(props) => (props.error ? 'none' : '#263549')} !important;
           outline: none !important;
       }
    }     
  }
 /*  #4183c4 blue 2.5*/
`;

export const Input = (props) =>  {
    return (
        <StyledInput {...props} />
    );
}
