import { 
    GET_SUPPLIER_CATEGORY, 
    GET_SUPPLIERS, 
    CHECKED_SUPPLIER_LIST,
    GET_SUPPLIER_PAYMENT, 
} from '../actions/actionConstants';

const initialState = {
    supplierCategories: [],
    suppliers: [],
    suppliersCount: 0,
    supplierPayments: [],
    supplierPaymentsCount: 0,
};
const suppliersReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_SUPPLIER_CATEGORY:
            return {
                ...state,
                supplierCategories: action.payload,
            };

        case GET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload.records,
                suppliersCount: action.payload.count,
            };

        case GET_SUPPLIER_PAYMENT:
            return {
                ...state,
                supplierPayments:  action.payload.records,
                supplierPaymentsCount: action.payload.count,
            };

        case CHECKED_SUPPLIER_LIST:
            const { type, index, checked } = action.payload;

            if (type === 'check_single') {
                const supplierList = state.suppliers;
                supplierList[index] = { ...supplierList[index], checked: checked };

                return { ...state, suppliers: supplierList };
            }

            if (type === 'check_all') {
                const supplierList = state.suppliers.map((obj, index) => {
                    return { ...obj, checked: checked }
                })
                return { ...state, suppliers: supplierList };
            }

            return state;
        default:
            return state;
    }
};

export default suppliersReducer;
